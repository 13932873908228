import React from 'react'

import { Dropdown } from '@sketch/components'

import {
  Chevron,
  DropdownButton,
  Strong,
} from './WorkspaceSettingsMobileNavbar.styles'
import { routes } from '@sketch/modules-common'
import { useHistory, useRouteMatch } from 'react-router'
import { ErrorHandler } from '@sketch/tracing'
import { CanRenderOptions } from '../../types'

const FALLBACK = {
  label: 'People',
  active: false,
}

interface WorkspaceSettingsMobileNavbarProps {
  canRender: CanRenderOptions
  workspaceId: string
}

const WorkspaceSettingsMobileNavbar: React.FC<
  WorkspaceSettingsMobileNavbarProps
> = ({ canRender, workspaceId }) => {
  const history = useHistory()

  const menuItems = [
    {
      canRender: canRender.peopleMenu,
      label: 'People',
      route: routes.WORKSPACE_SETTINGS_PEOPLE.create({
        workspaceId,
      }),
      active: useRouteMatch(routes.WORKSPACE_SETTINGS_PEOPLE.template()),
    },
    {
      canRender: canRender.permissionGroups,
      label: 'Groups',
      route: routes.WORKSPACE_SETTINGS_PERMISSION_GROUPS.create({
        workspaceId,
      }),
      active: useRouteMatch(
        routes.WORKSPACE_SETTINGS_PERMISSION_GROUPS.template()
      ),
    },
    {
      canRender: canRender.generalMenu,
      label: 'Settings',
      route: routes.WORKSPACE_SETTINGS_SETTINGS.create({
        workspaceId,
      }),
      active: useRouteMatch(routes.WORKSPACE_SETTINGS_SETTINGS.template()),
    },
    {
      canRender: canRender.profileMenu,
      label: 'Profile',
      route: routes.WORKSPACE_SETTINGS_PROFILE.create({
        workspaceId,
      }),
      active: useRouteMatch(routes.WORKSPACE_SETTINGS_PROFILE.template()),
    },
    {
      canRender: canRender.billingMenu,
      label: 'Billing',
      route: routes.WORKSPACE_SETTINGS_BILLING.create({
        workspaceId,
      }),
      active: useRouteMatch(routes.WORKSPACE_SETTINGS_BILLING.template()),
    },
    {
      canRender: canRender.SSOMenu,
      label: 'Single Sign-On',
      route: routes.WORKSPACE_SETTINGS_SSO.create({
        workspaceId,
      }),
      active: useRouteMatch(routes.WORKSPACE_SETTINGS_SSO.template()),
    },
  ]

  const currentActivePage =
    // There should always be an active page, but in case there isn't, we'll use the fallback to avoid breaking the UI
    menuItems.find(({ active, label }) => label && active) ?? FALLBACK

  if (!currentActivePage) {
    ErrorHandler.shouldNeverHappen(
      'There should always be an active Workspace Settings page'
    )
  }

  return (
    <Dropdown
      toggle={
        <DropdownButton>
          Settings: <Strong> {currentActivePage.label} </Strong>
          <Chevron role="img" />
        </DropdownButton>
      }
    >
      {menuItems.map(({ label, canRender, route, active }) => {
        if (!canRender) return null

        return (
          <Dropdown.Item
            key={label}
            className={active ? 'active' : undefined}
            onClick={() => history.push(route)}
          >
            {label}
          </Dropdown.Item>
        )
      })}
    </Dropdown>
  )
}

export default WorkspaceSettingsMobileNavbar
