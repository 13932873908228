import React from 'react'
import { Redirect, useLocation } from 'react-router'

import { routes, useFlag } from '@sketch/modules-common'

import { ErrorHandler } from '@sketch/tracing'

import { useProjectBanner } from '../../../projects/hooks/useProjectBanner'
import { useSearch } from '@sketch/utils'
import { useCollectionsShares } from '../../operations'
import { useSearchFiltersOld } from '../../../shares/hooks/useSearchFiltersOld'
import { useSortingSettings } from '../../../shares/hooks/useSortSettings'

import { DocumentHead, LoadingState, useModalContext } from '@sketch/components'
import DocumentItemsLayout from '../../../shares/components/DocumentItemsLayout'
import { DocumentItemDropdown } from '../../../shares/components/DocumentItemDropdown'
import CollectionError from '../../../projects/components/ProjectCollectionError'

import CollectionHeader from '../../components/CollectionHeader'
import CollectionEmptyState from '../../components/CollectionEmptyState'
import { ManageCollectionModal } from '../../modals'

import { WorkspaceMinimalFragment } from '@sketch/gql-types'
import { userIsEditorOrAdmin } from '../../../workspace/utils'

interface CollectionsViewProps {
  workspace: WorkspaceMinimalFragment
  projectId: string
  collectionId: string
}

const CollectionsView = ({
  workspace,
  projectId,
  collectionId,
}: CollectionsViewProps) => {
  const location = useLocation()
  const { showModal } = useModalContext()
  const { searchDebounced: search } = useSearch()
  const { filters } = useSearchFiltersOld()
  const { sort } = useSortingSettings()

  const isNestedProjectsOn = useFlag('nested-projects')

  const queryVariables = {
    projectId,
    collectionId,
    search,
    filters,
    sort,
  }

  const { project, loading, error, fetchMore } = useCollectionsShares({
    ...queryVariables,
  })

  const workspaceId = workspace.identifier

  useProjectBanner({
    project,
    workspaceId,
  })

  const collection = project?.collection
  const shares = collection?.shares.entries ?? []
  const totalShareCount = collection?.shares?.meta?.totalCount ?? 0

  const isCurrentlyInCollectionView =
    location.pathname ===
    routes.WORKSPACE_COLLECTION.create({
      workspaceId,
      projectId,
      collectionId,
    })

  const isCurrentlyInTrashCollectionView =
    location.pathname ===
    routes.WORKSPACE_TRASH_COLLECTION.create({
      workspaceId,
      projectId,
      collectionId,
    })

  // Redirect to project view when the `nested-projects` feature flag is on
  if (isNestedProjectsOn) {
    return (
      <Redirect
        to={routes.WORKSPACE_PROJECT.create({ workspaceId, projectId })}
      />
    )
  }

  if (loading) {
    return <LoadingState />
  }

  if (error || !collection) {
    return (
      <CollectionError type="collection" projectId={projectId!} error={error} />
    )
  }

  /* ==========
   * Handle redirects when trashing / restoring projects
   * ==========
   * > Redirect to the Trash Project View if the project has been soft-deleted */
  if (!!project?.deletedAt && isCurrentlyInCollectionView) {
    return (
      <Redirect
        to={routes.WORKSPACE_TRASH_COLLECTION.create({
          workspaceId,
          projectId,
          collectionId,
        })}
        push={false}
      />
    )
  }

  /* Redirect to the Project View if the project has been restored */
  if (!project?.deletedAt && isCurrentlyInTrashCollectionView) {
    return (
      <Redirect
        to={routes.WORKSPACE_COLLECTION.create({
          workspaceId,
          projectId,
          collectionId,
        })}
        push={false}
      />
    )
  }
  /* ==========
   * ========== */

  if (shares.length === 0) {
    const handleAddDocuments = () => {
      showModal(ManageCollectionModal, {
        workspaceIdentifier: workspaceId,
        project: project!,
        collection: collection!,
      })
    }

    return (
      <>
        <CollectionHeader
          project={project!}
          collection={collection!}
          workspace={workspace}
        />
        <CollectionEmptyState
          userCanManageCollection={userIsEditorOrAdmin(workspace)}
          onAddDocumentClick={handleAddDocuments}
          search={search}
        />
      </>
    )
  }

  return (
    <>
      <DocumentHead title={collection?.name} />
      <CollectionHeader
        project={project!}
        workspace={workspace}
        collection={collection!}
      />
      <DocumentItemsLayout
        workspaceId={workspaceId}
        shares={shares}
        renderDropdown={({ identifier }) => {
          const share = shares.find(share => share.identifier === identifier)
          if (!share) {
            ErrorHandler.shouldNeverHappen(
              'share should always be present rendering the ProjectDocumentDropdown'
            )
            return null
          }
          return (
            <DocumentItemDropdown
              share={share}
              workspaceIdentifier={workspace.identifier}
              queryVariables={queryVariables}
              workspaceStatus={workspace.status}
            />
          )
        }}
        onLoadMore={fetchMore}
        showProjectName={false}
        totalCount={totalShareCount}
        action={() => 'link'}
      />
    </>
  )
}

export default CollectionsView
