import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import {
  routes,
  IndexLayoutOldTitle,
  IndexLayoutExtraProps,
  IndexLayoutContent,
  IndexLayoutHeaderLink,
  useFetchInitialUser,
  useSetUserAuthorization,
} from '@sketch/modules-common'

import { useToast } from '@sketch/toasts'

import { Link, VerificationCodeInput } from '@sketch/components'

// GQL
import { useValidateMfaTotpMutation } from '@sketch/gql-types'

import { isPricingSourceRoute } from '../../../utils'

import {
  VerificationCodeContainer,
  LinkDescription,
  Subtitle,
  Wrapper,
} from './VerificationCodeView.styles'

interface MFAError {
  code: string
  message: string
  type: 'wrong_mfa_verification_code' | 'invalid_mfa_token'
}

interface LocationState {
  mfaToken: string
  from?: Location
}

export interface VerificationCodeViewProps extends IndexLayoutExtraProps {}

const VerificationCodeView = (props: VerificationCodeViewProps) => {
  const { HeaderPortal } = props

  const history = useHistory()
  const location = useLocation<LocationState>()

  const { showToast } = useToast()

  const setUserAuthorization = useSetUserAuthorization()

  const fetchInitialUser = useFetchInitialUser()

  const [validateOneTimePassword] = useValidateMfaTotpMutation({
    onCompleted: async data => {
      // Save the credentials (Sign the user in)
      setUserAuthorization(data.validateMfaTotp.credentials)

      // Get the user profile for the first time now, since <UserContext /> (App.tsx)
      // can't do it because the user is not signed in until now
      await fetchInitialUser()

      if (
        isPricingSourceRoute(location) &&
        data.validateMfaTotp.createdWorkspace
      ) {
        const workspaceId = data.validateMfaTotp.createdWorkspace
        history.push(routes.WORKSPACE_SUBSCRIBE.create({ workspaceId }))
        return
      }

      history.push(location.state?.from || routes.ENTRY.create({}))
    },
    onError: error => {
      // TODO: Improve returned error types from local resolvers
      // https://github.com/sketch-hq/Cloud/issues/11366
      const mfaError = error.message as unknown as MFAError

      showToast(mfaError.message, 'negative')

      if (mfaError.type === 'invalid_mfa_token') {
        history.push(routes.SIGN_IN.create({}), undefined)
      }
    },
  })

  const handleSubmit = (totp: string) => {
    validateOneTimePassword({
      variables: {
        token: location.state.mfaToken,
        totp,
        ...(isPricingSourceRoute(location) ? { createWorkspace: true } : {}),
      },
    })
  }

  return (
    <IndexLayoutContent center="horizontal" marginTop paddingHorizontal>
      <HeaderPortal>
        <IndexLayoutHeaderLink headerLink="back-sign-in" />
      </HeaderPortal>
      <Wrapper>
        <IndexLayoutOldTitle>Enter Verification Code</IndexLayoutOldTitle>
        <Subtitle>
          Open your authenticator app and enter the verification code for your
          Sketch account.
        </Subtitle>
        <VerificationCodeContainer>
          <VerificationCodeInput onFilled={handleSubmit} />
        </VerificationCodeContainer>
        <LinkDescription>
          Can’t access your authenticator app?{' '}
          <Link
            to={{
              pathname: routes.RECOVERY_CODE.create({}),
              search: location.search,
              state: {
                mfaToken: location.state.mfaToken,
                from: location.state.from,
              },
            }}
            variant="primary"
            isUnderlined
          >
            Use a recovery code
          </Link>
        </LinkDescription>
        <LinkDescription>
          Lost your recovery codes?{' '}
          <Link
            external
            variant="primary"
            isUnderlined
            href="https://www.sketch.com/support/contact/"
          >
            Contact us
          </Link>
        </LinkDescription>
      </Wrapper>
    </IndexLayoutContent>
  )
}

export default VerificationCodeView
