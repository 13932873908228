import React from 'react'
import Helmet from 'react-helmet'
import { useParams } from 'react-router'

import { useForTablet, NavbarExtended, SplitLayout } from '@sketch/components'
import {
  DynamicLoadingPage,
  Footer,
  GenericErrorView,
  useGetWorkspaces,
  useUserAuthorizations,
} from '@sketch/modules-common'
import {
  useLayoutOverrideProps,
  getItem,
  useCreateLayoutHost,
  createLayoutPortal,
} from '@sketch/utils'

import { NoWorkspaceSidebar as NoWorkspaceSidebarNew } from '../Sidebar'

import {
  SidebarWrapper,
  Margin,
  HeaderPortalContainer,
  HeaderSlimPortalContainer,
  NavbarPortalContainerDesktop,
  NavbarPortalContainerMobile,
} from './WorkspaceSidebarLayout.styles'

import { WorkspaceMinimalFragment } from '@sketch/gql-types'
import { localStorageKeys } from '@sketch/constants'
import { WorkspaceSidebar } from '../WorkspaceSidebar'
import { WorkspaceNavbar } from '../WorkspaceNavbar'

import * as layoutSymbols from './symbols'

export interface LayoutPassDownProps {
  title: string
  hideFooter?: boolean
  hideHeader?: boolean
  hasNavbar?: boolean
}

const LayoutComponents = {
  HeaderPortal: createLayoutPortal(layoutSymbols.header),
  HeaderSlimPortal: createLayoutPortal(layoutSymbols.headerSlim),
  NavbarPortal: createLayoutPortal(layoutSymbols.navbar),
  SubheaderPortal: createLayoutPortal(layoutSymbols.subHeader),
  WarningsPortal: createLayoutPortal(layoutSymbols.warnings),
}

export interface WorkspaceSidebarLayoutExtraProps {
  HeaderPortal: React.FunctionComponent<React.PropsWithChildren<{}>>
  HeaderSlimPortal: React.FunctionComponent<React.PropsWithChildren<{}>>
  NavbarPortal: React.FunctionComponent<React.PropsWithChildren<{}>>
  SubheaderPortal: React.FunctionComponent<React.PropsWithChildren<{}>>
  WarningsPortal: React.FunctionComponent<React.PropsWithChildren<{}>>
  useOverrideLayoutProps: (props: LayoutPassDownProps) => void
  workspace: WorkspaceMinimalFragment
}

interface RenderPropChildren {
  render: (props: WorkspaceSidebarLayoutExtraProps) => React.ReactElement
}

interface ComponentChildren {
  component: React.ComponentType<WorkspaceSidebarLayoutExtraProps>
}

export type WorkspaceSidebarLayoutProps = OneOf<
  RenderPropChildren,
  ComponentChildren
> & {
  title?: string
  hideFooter?: boolean
  hideHeader?: boolean
}

/**
 * In some views there is no need to have current workspace.
 * E.g. user settings, updates, discovery page and so on.
 *
 * So if there is no selected workspace, just pick first one from the list.
 * (if that list contains any items).
 */
export const getCurrentWorkspace = (
  workspaceId?: string,
  workspaces?: WorkspaceMinimalFragment[]
): WorkspaceMinimalFragment | undefined => {
  if (!workspaces) return undefined

  const lastWorkspaceId =
    workspaceId || getItem(localStorageKeys.lastWorkspaceIdKey)
  if (lastWorkspaceId)
    return workspaces.find(
      ({ identifier }) =>
        identifier.toLowerCase() === lastWorkspaceId.toLowerCase()
    )

  return workspaces[0]
}

export const WorkspaceSidebarLayout: React.FC<
  WorkspaceSidebarLayoutProps
> = props => {
  const params = useParams<{ workspaceId?: string }>()

  const { getOverriddenProps, useOverrideLayoutProps } =
    useLayoutOverrideProps<LayoutPassDownProps>()

  const {
    title = 'Workspace',
    hideFooter,
    hideHeader = false,
    hasNavbar = true,
    render,
    component: Component,
  } = getOverriddenProps(
    props as WorkspaceSidebarLayoutProps & LayoutPassDownProps
  )

  // Responsiveness
  const isTabletAndBigger = useForTablet()
  const isSmallerThanTablet = !isTabletAndBigger

  const { workspaces, loading, error } = useGetWorkspaces()
  const { hasAccessToWorkspace, hasPersonalAuthorization } =
    useUserAuthorizations()

  // Layout hooks
  const headerRef = useCreateLayoutHost(layoutSymbols.header)
  const headerSlimRef = useCreateLayoutHost(layoutSymbols.headerSlim)
  const navbarRef = useCreateLayoutHost(layoutSymbols.navbar)
  const subheaderRef = useCreateLayoutHost(layoutSymbols.subHeader)
  const warningsRef = useCreateLayoutHost(layoutSymbols.warnings)

  if (loading) {
    return <DynamicLoadingPage />
  }

  if (error) {
    return <GenericErrorView error={error} />
  }

  const validWorkspaces = workspaces?.filter(workspace =>
    hasAccessToWorkspace({
      identifier: workspace.identifier,
      customer: workspace.customer,
      userRole: workspace?.userRole,
    })
  )

  const workspace = getCurrentWorkspace(params.workspaceId, validWorkspaces)
  const missingAuthorizationsToAccessWorkspaces =
    (validWorkspaces?.length || 0) === 0 && !hasPersonalAuthorization

  const childrenProps = {
    // TODO: once every children is using useLayoutFromContext, we can remove portals from here
    ...LayoutComponents,
    useOverrideLayoutProps,

    // "workspace!" is used to avoid ts(2345) error with render() and <Component />.
    // This will not break anything because the check is being made before
    // calling does components.
    workspace: workspace!,
  }

  const NavbarPortalContainer = isSmallerThanTablet
    ? NavbarPortalContainerMobile
    : NavbarPortalContainerDesktop

  return (
    <>
      <Helmet>
        <meta name="theme-color" content="#F0F0F0" />
      </Helmet>
      <SplitLayout
        title={title}
        header={({ setSidebarLeftOpen, isSidebarLeftOpen }) => (
          <NavbarExtended
            isSidebarLeftOpen={isSidebarLeftOpen}
            singleContent={hasNavbar && !isSmallerThanTablet}
          >
            {workspace && (
              <WorkspaceNavbar
                workspace={workspace}
                setSidebarLeftOpen={setSidebarLeftOpen}
              />
            )}

            {!hideHeader && (
              <>
                {!isSmallerThanTablet && <div ref={warningsRef} />}

                <HeaderPortalContainer
                  data-testid="header-portal"
                  ref={headerRef}
                />

                <HeaderSlimPortalContainer
                  data-testid="header-slim-portal"
                  ref={headerSlimRef}
                />

                <NavbarPortalContainer ref={navbarRef} />
              </>
            )}
          </NavbarExtended>
        )}
        footer={!hideFooter && <Footer />}
        sidebar={
          <SidebarWrapper>
            {!workspace || workspaces?.length === 0 ? (
              <NoWorkspaceSidebarNew
                missingAuthorizations={missingAuthorizationsToAccessWorkspaces}
              />
            ) : (
              <WorkspaceSidebar workspace={workspace} />
            )}
          </SidebarWrapper>
        }
      >
        {isSmallerThanTablet && <div ref={warningsRef} />}
        <div ref={subheaderRef} />

        <Margin>
          {render && render(childrenProps)}
          {Component && <Component {...childrenProps} />}
        </Margin>
      </SplitLayout>
    </>
  )
}
