import React from 'react'
import styled from 'styled-components'
import { Avatar } from '../Avatar'

const Border = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px dashed ${({ theme }) => theme.colors.border.A};
  align-items: center;
  padding: 8px;
  background: ${({ theme }) => theme.colors.background.secondary.B};
  border-radius: 6px;
`

const Email = styled.span`
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  font-size: ${({ theme }) => theme.fontSizes.E};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin: 0 8px;
`

const HiddenInput = styled.input`
  opacity: 0;
  height: 0;
  position: absolute;
  pointer-events: none;
`

interface UserChipProps {
  name: string
  email: string
  avatarSrc?: string
}

export const UserChip = ({ name, email, avatarSrc }: UserChipProps) => {
  return (
    <Border data-testid="user-chip">
      <Avatar name={name} src={avatarSrc} size={24} />
      <Email>{email}</Email>

      {/* Hidden input field to help password managers determine for which email (username) it should suggest passwords */}
      <HiddenInput
        type="email"
        name="email"
        title="Email"
        value={email}
        autoComplete="email"
        readOnly
        tabIndex={-1}
      ></HiddenInput>
    </Border>
  )
}
