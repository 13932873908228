import { dataIdFromObject } from '@sketch/graphql-cache'

import { useOnEvent } from '@sketch/utils'
import { isNetworkRequestInFlight } from 'apollo-client/core/networkStatus'

import { handleFetchMore } from '@sketch/components'

import { useGetProjectCollections } from '../../../collections/hooks'
import { useProjectBanner } from '../useProjectBanner'
import { useGetProjectShares } from './useGetProjectShares'

import { ShareSearchFilter } from '../../../shares/hooks/useSearchFiltersOld'

interface UseProjectsProps {
  workspaceId: string
  projectId: string
  search?: string
  filters?: ShareSearchFilter[]
}

const sharesEntriesPath = ['project', 'shares', 'entries']
const collectionsEntriesPath = ['project', 'collections', 'entries']

export const useProject = ({
  workspaceId,
  projectId,
  search,
  filters = [],
}: UseProjectsProps) => {
  const {
    data: projectSharesData,
    networkStatus: projectSharesNetworkStatus,
    fetchMore: fetchMoreProjectShares,
    error: projectSharesError,
    refetch: refetchProjectShares,
  } = useGetProjectShares({
    projectIdentifier: projectId,
    searchTerm: search,
    filters,
  })

  const {
    data: collectionsData,
    networkStatus: collectionNetworkStatus,
    fetchMore: fetchMoreCollections,
    error: collectionsError,
    refetch: refetchProjectCollections,
  } = useGetProjectCollections({
    projectIdentifier: projectId,
    searchTerm: search,
    filters,
  })

  useOnEvent(
    'workspaceShareRefresh',
    ({ workspaceIdentifier, projectIdentifier }) => {
      if (workspaceIdentifier !== workspaceId) {
        // Not the workspace visible ignore then
        return
      }

      if (projectIdentifier === projectId) {
        refetchProjectShares()
      }

      refetchProjectCollections()
    }
  )

  useProjectBanner({ project: projectSharesData?.project, workspaceId })

  const loading =
    isNetworkRequestInFlight(projectSharesNetworkStatus) ||
    isNetworkRequestInFlight(collectionNetworkStatus)

  const error = projectSharesError || collectionsError

  const project = projectSharesData?.project
  const afterShares = project?.shares.meta.after ?? null
  const afterCollections =
    collectionsData?.project.collections.meta.after ?? null

  const loadMoreSharesHandler = handleFetchMore(
    fetchMoreProjectShares,
    sharesEntriesPath,
    { dataIdFromObject, after: afterShares }
  )

  const loadMoreCollectionsHandler = handleFetchMore(
    fetchMoreCollections,
    collectionsEntriesPath,
    { dataIdFromObject, after: afterCollections }
  )

  return {
    data: {
      project: projectSharesData?.project,
      collections: collectionsData?.project.collections,
    },
    loadMore: {
      project: loadMoreSharesHandler,
      collections: loadMoreCollectionsHandler,
    },
    loading,
    error,
  }
}
