import React from 'react'

import { Button } from '@sketch/components'

import {
  Wrapper,
  Title,
  Description,
  CallToActionWrapper,
  IconWrapper,
  PageFiller,
} from './EmptyState.styles'

// Icon
import { ReactComponent as DocumentIcon } from '@sketch/icons/document-64'
import { ReactComponent as DocumentPlaceholderIcon } from '@sketch/icons/document-placeholder-64'
import { ReactComponent as LinkLibraryIcon } from '@sketch/icons/link-library-64'
import { ReactComponent as PersonCircle } from '@sketch/icons/person-circle-64'
import { ReactComponent as SearchIcon } from '@sketch/icons/search-64'
import { ReactComponent as NotesIcon } from '@sketch/icons/notes.64'
import { ReactComponent as TrashBinIcon } from '@sketch/icons/trash-bin-64'
import { ReactComponent as WorkspaceIcon } from '@sketch/icons/workspace-64'
import { ReactComponent as SymbolIcon } from '@sketch/icons/symbol-64'
import { ReactComponent as LayerStyleIcon } from '@sketch/icons/paint-brush-64'
import { ReactComponent as TextStyleIcon } from '@sketch/icons/text-format-64'
import { ReactComponent as ColorVariableIcon } from '@sketch/icons/color-swatch-64'
import { ReactComponent as UploadIcon } from '@sketch/icons/uploading-64'
import { ReactComponent as TemplateIcon } from '@sketch/icons/template-64'
import { ReactComponent as GroupCircle } from '@sketch/icons/group-circle-64'

const ICON_BY_NAME = {
  document: DocumentIcon,
  documentPlaceholder: DocumentPlaceholderIcon,
  link: LinkLibraryIcon,
  search: SearchIcon,
  notes: NotesIcon,
  'person-circle': PersonCircle,
  'trash-bin': TrashBinIcon,
  workspace: WorkspaceIcon,
  symbol: SymbolIcon,
  layerStyle: LayerStyleIcon,
  textStyle: TextStyleIcon,
  colorVariable: ColorVariableIcon,
  upload: UploadIcon,
  template: TemplateIcon,
  'group-circle': GroupCircle,
}

interface EmptyStateLayoutProps {
  title: React.ReactNode
  description: React.ReactNode
  callToAction?: React.ReactNode
  icon?: keyof typeof ICON_BY_NAME
}

export const EmptyState: React.FC<EmptyStateLayoutProps> = props => {
  const { title, description, callToAction, icon } = props

  const Icon = ICON_BY_NAME[icon || 'document']

  return (
    <PageFiller>
      <Wrapper>
        <IconWrapper>
          <Icon />
          <span className="sr-only">{icon} icon</span>
        </IconWrapper>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
        {callToAction && (
          <CallToActionWrapper>{callToAction}</CallToActionWrapper>
        )}
      </Wrapper>
    </PageFiller>
  )
}

interface EmptyStateDueToSearchProps {
  onClear: () => void
  typeName: 'libraries' | 'projects' | 'documents'
  icon?: keyof typeof ICON_BY_NAME
  title?: string
  description?: string
}

export const EmptyStateDueToSearch = (props: EmptyStateDueToSearchProps) => {
  const {
    typeName,
    onClear,
    icon = 'search',
    title = `No ${typeName} matched your filter`,
    description = `Try adjusting your search term or filters to find the ${typeName} you're looking for`,
  }: typeof props = props

  return (
    <EmptyState
      icon={icon}
      title={title}
      description={description}
      callToAction={
        <Button variant="tertiary" onClick={onClear}>
          Reset Search
        </Button>
      }
    />
  )
}
