import styled from 'styled-components'

import { IndexLayoutOldSubtitle } from '@sketch/modules-common'
import { Text } from '@sketch/components'

export const Wrapper = styled.div`
  height: 100%;
  max-width: 448px;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const VerificationCodeContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`

export const LinkDescription = styled(Text).attrs({
  textStyle: 'copy.tertiary.standard.C',
})`
  margin-top: 4px;
  text-align: center;
`

export const Subtitle = styled(IndexLayoutOldSubtitle)`
  max-width: 448px;
  margin: 8px auto 0;
`
