import { useState, useEffect } from 'react'
import { Location, History } from 'history'
import qs from 'query-string'

import debounce from 'debounce'
import { urlQueryKeys } from '@sketch/constants'

const DEBOUNCE_INTERVAL = 500

const setSearchQuery = (
  searchValue: string,
  history: History,
  previousQuery: Record<
    | (typeof urlQueryKeys)['search']
    // rest isn't an actual property name - it represent that previousQuery can have other fields
    // that we don't know about
    | '__rest__',
    string
  >
) => {
  // When the user clears a search we remove the search query param
  if (!searchValue && previousQuery?.search) {
    // Drop the search term but keep the other query parameters
    const { search: _, ...newQuery } = previousQuery
    history.push({ search: qs.stringify(newQuery) })
    return
  }

  if (!searchValue && previousQuery?.search) {
    // If there's no search then we should clear the URL parameter
    const { search, ...otherParameters } = previousQuery

    history.push({
      search: qs.stringify(otherParameters),
    })
  } else if (!searchValue) {
    // If there's no search then we should clear the URL parameter
    return
  }

  history.push({
    search: qs.stringify({
      ...previousQuery,
      search: searchValue,
    }),
  })
}

const setSearchQueryDebounced = debounce(setSearchQuery, DEBOUNCE_INTERVAL)

export const useURLQuerySearch = (location: Location, history: History) => {
  const queryArguments = qs.parse(location.search) as Record<string, string>
  const searchQuery = queryArguments.search || ''
  const [search, setSearchValue] = useState(searchQuery)

  /**
   * Sync the internal search with the url search
   */
  useEffect(() => {
    setSearchValue(searchQuery)
  }, [searchQuery])

  return {
    search,
    searchDebounced: searchQuery,
    setSearch: (searchValue: string) => {
      setSearchValue(searchValue)
      setSearchQueryDebounced(searchValue, history, queryArguments)
    },
    setSearchImmediately: (searchValue: string) => {
      setSearchValue(searchValue)
      setSearchQuery(searchValue, history, queryArguments)
    },
  }
}
