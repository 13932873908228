import React from 'react'

import { ZoomTypes } from '../../../../components/Zoom'
import { Zoom, ZoomProps } from '../../../../components/Zoom/Zoom'

import DocumentNavbar from '../DocumentNavbar'
import PlayPrototypeLink from '../PlayPrototypeLink'
import { IS_EMBEDDED } from '@sketch/constants'

import {
  Wrapper,
  TextToolbar as Text,
  AddAnnotationWrapper,
} from './DocumentMobileToolbar.styles'
import { AddAnnotationNavbarItem } from '../../../annotations/components'
import { VerticalDivider } from '../NavbarItem'
import { MenuIconButton } from '@sketch/components'
import { useToggleSidebarTab } from '../ShareSidebarTabContext'

import { ReactComponent as ChatBubbles } from '@sketch/icons/chat-bubbles-24'
import { ReactComponent as Information } from '@sketch/icons/information-24'
import { ReactComponent as ClockReWind } from '@sketch/icons/clock-rewind-24'

const TOOLBOX_ITEMS = [
  'doc-info',
  'search',
  'zoom',
  'prototype',
  'add-comment',
  'comments',
  'versions',
] as const

type ToolboxItem = (typeof TOOLBOX_ITEMS)[number]

const DRAFT_ANNOTATION_HEADER_ARTBOARD = {
  true: 'Tap on the Artboard to comment',
  false: 'Tap anywhere to comment',
}

interface DocumentMobileToolboxProps {
  items: ToolboxItem[]
  zoom?: ZoomTypes
  setZoom?: ZoomProps['setZoom']
  shareIdentifier?: string
  // TODO: Rename artboardUUID to frameGroupUUID
  // https://linear.app/sketch/issue/SWEB-510
  artboardUUID?: string
  isPrototypePlayEnabled?: boolean
  isDraftAnnotationEnabled?: boolean
  setPlaceDraftAnnotation?: (state: boolean) => void
  disableAddAnnotationButton?: boolean
  gridAndLayout?: ZoomProps['gridAndLayout']
}

export const isMobileToolboxItem = (item: any): item is ToolboxItem => {
  return TOOLBOX_ITEMS.includes(item)
}

export const DocumentMobileToolbar = (props: DocumentMobileToolboxProps) => {
  const {
    items,
    zoom,
    setZoom,
    shareIdentifier,
    artboardUUID,
    isPrototypePlayEnabled,
    isDraftAnnotationEnabled,
    disableAddAnnotationButton,
    gridAndLayout,
  } = props

  const showDocInfo = items.includes('doc-info')
  const showSearch = items.includes('search') && !IS_EMBEDDED
  const showZoom = items.includes('zoom')
  const showPrototype = items.includes('prototype')
  const showAddComment = items.includes('add-comment')
  const showComments = items.includes('comments')
  const showVersions = items.includes('versions')

  const { toggle } = useToggleSidebarTab()

  if (isDraftAnnotationEnabled) {
    const title =
      DRAFT_ANNOTATION_HEADER_ARTBOARD[`${Boolean(artboardUUID)}` as const]

    return (
      <Wrapper>
        <Text>{title}</Text>
        {showZoom && zoom && setZoom && (
          <Zoom zoom={zoom} setZoom={setZoom} gridAndLayout={gridAndLayout} />
        )}
        <AddAnnotationNavbarItem disabled={disableAddAnnotationButton} />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      {/* Prototype */}
      {showPrototype && shareIdentifier && artboardUUID && (
        <PlayPrototypeLink
          shareIdentifier={shareIdentifier}
          isPrototypePlayEnabled={!!isPrototypePlayEnabled}
          frameGroupUUID={artboardUUID}
          representation="toolbar"
        />
      )}

      {/* Search */}
      {showSearch && <DocumentNavbar forceExpand />}

      {/* Add new comment */}
      {showAddComment && (
        <AddAnnotationWrapper>
          <AddAnnotationNavbarItem disabled={disableAddAnnotationButton} />
        </AddAnnotationWrapper>
      )}

      {/* Only show separator if there's something to separate */}
      {showAddComment && (showDocInfo || showComments || showVersions) && (
        <VerticalDivider />
      )}

      {/* Zoom, positioned inside the canvas */}
      {showZoom && zoom && setZoom && (
        <Zoom zoom={zoom} setZoom={setZoom} gridAndLayout={gridAndLayout} />
      )}

      {/* Doc info */}
      {showDocInfo && (
        <MenuIconButton onClick={() => toggle('About')}>
          <Information role="img" />
          <span className="sr-only">About</span>
        </MenuIconButton>
      )}

      {/* Versions */}
      {showVersions && (
        <MenuIconButton onClick={() => toggle('Version')}>
          <ClockReWind role="img" />
          <span className="sr-only">Versions</span>
        </MenuIconButton>
      )}

      {/* Comments */}
      {showComments && (
        <MenuIconButton onClick={() => toggle('Comment')}>
          <ChatBubbles role="img" />
          <span className="sr-only">Comments</span>
        </MenuIconButton>
      )}
    </Wrapper>
  )
}
