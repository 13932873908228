import { useCallback } from 'react'
import qs from 'query-string'

import { urlQueryKeys } from '@sketch/constants'
import { useHistory, useLocation } from 'react-router-dom'
import { omit } from '../../omit'

export function useClearUrlQuery() {
  const history = useHistory()
  const location = useLocation()

  const clearUrlQuery = useCallback(
    (
      keys: (keyof typeof urlQueryKeys)[] = [],
      method: 'replace' | 'push' = 'replace'
    ) => {
      const previousQuery = qs.parse(location.search)

      const newQuery = keys.length > 0 ? omit(previousQuery, keys) : {}
      history[method]({ search: qs.stringify(newQuery) })
    },
    [history, location.search]
  )
  return { clearUrlQuery }
}
