import React, { useEffect, useRef } from 'react'

import { useHistory } from 'react-router-dom'
import { InView } from 'react-intersection-observer'

import { ResponsiveValues } from '@sketch/global-styles'

import { clamp, useSearch } from '@sketch/utils'
import { routes, useFlag } from '@sketch/modules-common'

import NestedProjectItem, {
  NestedProjectItemGridViewSkeleton,
  NestedProjectItemListViewSkeleton,
} from '../NestedProjectItem'
import {
  Table,
  Breakpoint,
  AspectRatioGrid,
  Pill,
  pluralize,
} from '@sketch/components'

import {
  ActionTableCell,
  FocusLink,
  HeaderTableCell,
} from '../../../shares/components/DocumentItemsLayout/DocumentItemsLayout.styles'
import { Header } from '../../../projects/components/ProjectShares/ProjectShares.styles'
import { TableWrapper } from './NestedProjectItemsLayout.styles'

import { RenderNestedProjectDropdown } from '../NestedProjectItem/types'

import {
  NestedProjectFragment,
  ShareListItemFragment,
  useGetDocumentListSettingsQuery,
} from '@sketch/gql-types'
import { UrlQueryKeys, urlQueryKeys } from '@sketch/constants'
import {
  StorageItemFilter,
  useStorageItemsFilters,
  storageItemFiltersToFlags,
} from '../../../storageItems/hooks'

type Share = ShareListItemFragment
type NestedProject = NestedProjectFragment

interface CustomColumns {
  customColumns?: ResponsiveValues<number>
}

type Action = (item: Share | NestedProject) => (() => void) | 'link'

interface NestedProjectUrlProps {
  workspaceId: string
  nestedProjectId: string
  search: string | null | undefined
  filters: StorageItemFilter
}

const nestedProjectUrl = ({
  workspaceId,
  nestedProjectId,
  search,
  filters,
}: NestedProjectUrlProps): string => {
  const query: Partial<
    Record<UrlQueryKeys['filter'] | UrlQueryKeys['search'], string>
  > = {}
  if (search) {
    query[urlQueryKeys.search] = search
  }
  const flags = storageItemFiltersToFlags(filters)
  if (flags.length > 0) {
    query[urlQueryKeys.filter] = flags.join(',')
  }

  return routes.WORKSPACE_PROJECT.create({
    workspaceId,
    projectId: nestedProjectId,
    query,
  })
}

interface NestedProjectItemsLayoutProps extends CustomColumns {
  workspaceId: string
  projectId: string

  nestedProjects: NestedProject[]
  totalCount?: number

  onLoadMore: () => Promise<any>
  renderDropdown: RenderNestedProjectDropdown
  action?: Action
  search?: string

  canUseDesignSystemManager?: boolean
}

const COLUMNS: ResponsiveValues<number> = [1, 1, 2, 2, 2, 3, 4, 5]
const GUTTER: ResponsiveValues<number> = [16, 16, 16, 16, 24, 24, 24]
const VERTICAL_SPACE: ResponsiveValues<number> = [24, 24, 24, 24, 24, 24, 24]
const PAGE_SIZE = 20

const buildPlaceholderArray = (remainingSharesToLoad: number) =>
  Array(clamp(remainingSharesToLoad, 0, PAGE_SIZE))

const NestedProjectItemsLayout: React.FC<
  NestedProjectItemsLayoutProps
> = props => {
  const {
    onLoadMore,
    action: actionCreator = () => 'link',
    renderDropdown,
    nestedProjects,
    projectId,
    workspaceId,
    totalCount = 0,
    customColumns,
  } = props

  const { searchDebounced: search } = useSearch()
  const { filters } = useStorageItemsFilters()
  const isNestedProjectsOn = useFlag('nested-projects')

  // Make sure the onLoadMore Reference is always the latest
  const onLoadMoreRef = useRef<() => Promise<any>>(onLoadMore)
  const history = useHistory()

  useEffect(() => {
    onLoadMoreRef.current = onLoadMore
  }, [onLoadMore])

  // Load the document layout
  const { data } = useGetDocumentListSettingsQuery()
  const { documentsLayout } = data || {}

  const renderGridItem = (nestedProject: NestedProject, isMobile: boolean) => {
    const action = actionCreator(nestedProject)

    if (action !== 'link') {
      return (
        <NestedProjectItem
          key={nestedProject.identifier}
          nestedProject={nestedProject}
          projectIdentifier={projectId}
          workspaceIdentifier={workspaceId}
          renderDropdown={renderDropdown}
          presentation="grid"
          onClick={action}
        />
      )
    }

    return (
      <FocusLink
        key={nestedProject.identifier}
        draggable={false}
        to={nestedProjectUrl({
          workspaceId: workspaceId,
          nestedProjectId: nestedProject.identifier,
          search: search,
          filters: filters,
        })}
      >
        <NestedProjectItem
          key={nestedProject.identifier}
          projectIdentifier={projectId}
          nestedProject={nestedProject}
          workspaceIdentifier={workspaceId}
          renderDropdown={renderDropdown}
          presentation="grid"
        />
      </FocusLink>
    )
  }

  const renderTableItem = (item: NestedProject) => {
    const action = actionCreator(item)
    return (
      <NestedProjectItem
        nestedProject={item}
        projectIdentifier={projectId}
        workspaceIdentifier={workspaceId}
        renderDropdown={renderDropdown}
        presentation="list"
        onClick={event => {
          if (action !== 'link') {
            action()
            return
          }

          const link = nestedProjectUrl({
            workspaceId: workspaceId,
            nestedProjectId: item.identifier,
            search,
            filters,
          })

          if (event.metaKey) {
            /* Simulate the link opening with meta key */
            window.open(link, '_blank')
          } else {
            history.push(link)
          }
        }}
      />
    )
  }

  const renderLayout = (
    isMobile: boolean,
    customColumns?: ResponsiveValues<number>
  ) => {
    const placeholders = buildPlaceholderArray(
      (totalCount || 0) - nestedProjects.length
    )

    // Merge the items with the placeholders have them rendered together
    // This prevents the page from bumping when the placeholders are replaced
    // with actual items
    const nestedProjectsAndPlaceholders = [...nestedProjects, ...placeholders]

    // Create the InView listener warn when it starts showing the placeholders, this will load the next page
    const inViewListener =
      placeholders.length > 0 ? (
        <InView onChange={inView => inView && onLoadMoreRef.current?.()}>
          <span className="sr-only">Loading Placeholder</span>
        </InView>
      ) : null

    if (documentsLayout === 'LIST') {
      const headerLabel = pluralize('Project', 'Projects', totalCount)

      return (
        <>
          <TableWrapper>
            <Table
              header={[
                {
                  label: `${totalCount} ${headerLabel}`,
                  customCell: HeaderTableCell,
                },
                {
                  label: isNestedProjectsOn ? 'Items' : 'Documents',
                  customCell: HeaderTableCell,
                },
                { label: '', customCell: ActionTableCell },
              ]}
              items={nestedProjectsAndPlaceholders}
              renderItemKey={nestedProject =>
                // if it's not a share the table will generate a index based key
                typeof nestedProject === 'object'
                  ? nestedProject.identifier
                  : undefined
              }
              renderItem={item => {
                if (typeof item === 'object') {
                  return renderTableItem(item)
                }

                return (
                  <NestedProjectItemListViewSkeleton
                    inViewListener={inViewListener}
                  />
                )
              }}
              evenColumns={!isMobile}
            />
          </TableWrapper>
        </>
      )
    } else {
      return (
        <>
          {!isMobile && (
            <Header>
              Projects <Pill variant="secondary">{totalCount}</Pill>
            </Header>
          )}
          <AspectRatioGrid
            columns={customColumns ?? COLUMNS}
            verticalRowSpace={VERTICAL_SPACE}
            gutterSize={GUTTER}
          >
            {nestedProjectsAndPlaceholders.map((nestedProject, index) => {
              if (typeof nestedProject === 'object') {
                return renderGridItem(nestedProject, isMobile)
              }

              return (
                <NestedProjectItemGridViewSkeleton
                  key={index}
                  inViewListener={inViewListener}
                />
              )
            })}
          </AspectRatioGrid>
        </>
      )
    }
  }

  return (
    <>
      <Breakpoint on="sm">
        {matches => renderLayout(!matches, customColumns)}
      </Breakpoint>
    </>
  )
}

export default NestedProjectItemsLayout
