import React, { useMemo, useState } from 'react'

import { Table, useBreakpoint } from '@sketch/components'

import EmptyState from '../../../../../shares/components/EmptyStateOld'

import LargeDocumentListItem, { CommonProps } from '../LargeDocumentListItem'

import {
  FullWidthContainer,
  SelectAllCheck,
} from '../../ReviewLargeDocumentsModal.styles'

import {
  EmptyStateWrapper,
  StyledTableHeaderCellButton,
  StyledTableHeaderCellCheckbox,
  StyledTableHeaderCellDocument,
  StyledTableHeaderCellFixed,
  Wrapper,
} from './SelectableLargeDocumentTable.styles'

import { ShareWithSizeFragment } from '@sketch/gql-types'
import { SelectedItemsProps } from '../types'

export interface SelectableLargeDocumentTableProps extends CommonProps {
  shares: ShareWithSizeFragment[]
  selectedItems: SelectedItemsProps[]
  isSelectAll?: boolean
  onSelectAll: () => void
}

type SortKeys = 'version' | 'size'
type SortOrder = 'ASC' | 'DESC'

const sortSharesByVersions = (shares: ShareWithSizeFragment[]) =>
  shares.sort(
    (a, b) =>
      (b.share.versions?.meta.totalCount ?? 0) -
      (a.share.versions?.meta.totalCount ?? 0)
  )

export const SelectableLargeDocumentTable = ({
  shares,
  selectedItems,
  onChange,
  isSelectAll,
  onSelectAll,
}: SelectableLargeDocumentTableProps) => {
  const [sortType, setSortType] = useState<SortKeys>('size')
  const [sortOrder, setSortOrder] = useState<SortOrder>('DESC')

  const isMobile = !useBreakpoint('xs')

  const sortedByType = useMemo(
    () => (sortType === 'version' ? sortSharesByVersions(shares) : shares),
    [shares, sortType]
  )

  const sorted = useMemo(
    () =>
      sortOrder === 'DESC' ? sortedByType : sortedByType.slice().reverse(),
    [sortOrder, sortedByType]
  )

  const selectAllLabel = useMemo(
    () => (
      <SelectAllCheck
        checked={isSelectAll}
        name="selectAll"
        onChange={onSelectAll}
        data-testid="selectable-large-document-selectAll"
      />
    ),
    [isSelectAll, onSelectAll]
  )

  const tableHeader = useMemo(
    () => [
      {
        label: selectAllLabel,
        customCell: StyledTableHeaderCellCheckbox,
      },
      { label: 'Document', customCell: StyledTableHeaderCellDocument },
      {
        label: 'Versions',
        sortKey: 'version',
        sort: true,
        iconVisible: sortType === 'version',
        customCell: StyledTableHeaderCellFixed,
      },
      {
        label: 'Total size',
        sortKey: 'size',
        sort: true,
        iconVisible: sortType === 'size',
        customCell: StyledTableHeaderCellFixed,
      },
      {
        label: '', // Empty cell for the dropdown button
        customCell: StyledTableHeaderCellButton,
      },
    ],
    [selectAllLabel, sortType]
  )

  if (!shares.length) {
    return (
      <EmptyStateWrapper data-testid="empty-selectable-document-list">
        <EmptyState
          title="No documents"
          description="This Workspace has no documents yet."
          icon="documentPlaceholder"
        />
      </EmptyStateWrapper>
    )
  }

  return (
    <FullWidthContainer>
      <Wrapper data-testid="selectable-document-list">
        <Table
          header={tableHeader}
          hideHeader={isMobile}
          sortDirection={sortOrder}
          sortable
          onHeaderClick={(sortKey: SortKeys) => {
            setSortType(sortKey)
            setSortOrder(prev => (prev === 'ASC' ? 'DESC' : 'ASC'))
          }}
          items={sorted}
          renderItem={item => {
            const checked =
              selectedItems.filter(
                selectedItem =>
                  selectedItem.identifier === item.share.identifier
              ).length > 0

            return (
              <LargeDocumentListItem
                shareWithSize={item}
                onChange={onChange}
                checked={checked}
                isSelectAll={isSelectAll}
              />
            )
          }}
          evenColumns
        />
      </Wrapper>
    </FullWidthContainer>
  )
}

export default SelectableLargeDocumentTable
