import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// eslint-disable-next-line no-restricted-imports
import { StorageItemFilterType } from '@sketch/gql-types/expansive'
import { isTruthy } from '@sketch/utils'
import { urlQueryKeys } from '@sketch/constants'
import { ShareSearchFilter } from '../../shares/hooks/useSearchFiltersOld'

export type StorageItemFilterTypes = StorageItemFilterType[]

/**
 * URL parameters which act as a flag, absence of the flag means false
 * e.g. `filters=with-prototypes,editable` means withPrototypes=true, editable=true, skipNestedFolders=false
 *
 * There is no way to pass false values, so if we need a value by default to be false,
 * we need to invert the name of the flag, e.g. instead of `search-in-folders` with a default `true`
 * we can name it `search-ignoring-nested-folders` with a default `false`
 */
export type StorageItemFilterFlags =
  | 'with-prototypes'
  | 'user-can-edit' // include documents that user can edit
  | 'search-ignoring-nested-folders' // search for documents only in the current folder (ignoring nested folders)

const allowedFlagsObj: { [key in StorageItemFilterFlags]: null } = {
  'with-prototypes': null,
  'user-can-edit': null,
  'search-ignoring-nested-folders': null,
}

const allowedFlags = Object.keys(allowedFlagsObj) as StorageItemFilterFlags[]

export interface StorageItemFilter {
  documents: { withPrototypes: boolean; userCanEdit: boolean } | undefined
  /**
   * Search only in the current folder, ignoring nested folders
   */
  searchIgnoringNestedFolders: boolean
}

const CONNECTOR = ','
const PARAM_NAME = urlQueryKeys.filter

const parseFilterFlags = (flags: string): StorageItemFilterFlags[] => {
  const stringParts = flags.split(CONNECTOR).filter(x => x.length > 0)
  return allowedFlags.filter(flag => stringParts.includes(flag))
}

export const flagsToStorageItemFilter = (
  flags: StorageItemFilterFlags[]
): StorageItemFilter => {
  const withPrototypes = flags.includes('with-prototypes')
  const editable = flags.includes('user-can-edit')
  const skipNestedFolders = flags.includes('search-ignoring-nested-folders')

  return {
    documents:
      editable || withPrototypes
        ? { withPrototypes, userCanEdit: editable }
        : undefined,
    searchIgnoringNestedFolders: skipNestedFolders,
  }
}

export const storageItemFiltersToFlags = (
  filters: StorageItemFilter
): StorageItemFilterFlags[] => {
  const paramsRaw: (StorageItemFilterFlags | false | undefined)[] = [
    filters.searchIgnoringNestedFolders && 'search-ignoring-nested-folders',
    filters.documents?.withPrototypes && 'with-prototypes',
    filters.documents?.userCanEdit && 'user-can-edit',
  ]

  return paramsRaw.filter(isTruthy)
}

export const storageItemFiltersToShareFilters = (
  filters: StorageItemFilter
): ShareSearchFilter[] => {
  if (!filters.documents) return []

  const paramsRaw: (ShareSearchFilter | false | undefined)[] = [
    filters.documents.withPrototypes && 'HAS_PROTOTYPES',
    filters.documents.userCanEdit && 'I_CAN_EDIT',
  ]

  return paramsRaw.filter(isTruthy)
}

export const useStorageItemsFilters = () => {
  const history = useHistory()
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  const flags = parseFilterFlags(searchParams.get(PARAM_NAME) || '')

  const filters = flagsToStorageItemFilter(flags)

  const clearFilters = useCallback(() => {
    searchParams.delete(PARAM_NAME)
    history.replace({ search: searchParams.toString() })
  }, [history, searchParams])

  const setFlags = useCallback(
    (newFlags: StorageItemFilterFlags[]) => {
      if (newFlags.length === 0) {
        clearFilters()
        return
      }

      const validatedFlags = allowedFlags.filter(flag =>
        newFlags.includes(flag)
      )
      const filtersStr = validatedFlags.join(CONNECTOR)
      searchParams.set(PARAM_NAME, filtersStr)

      history.replace({ search: searchParams.toString() })
    },
    [searchParams, history, clearFilters]
  )

  const setFilters = useCallback(
    (newFilters: StorageItemFilter) => {
      const queryFlags = storageItemFiltersToFlags(newFilters)
      setFlags(queryFlags)
    },
    [setFlags]
  )

  const hasFilters = flags.length > 0

  const toggleFilterFlag = useCallback(
    (flag: StorageItemFilterFlags) => {
      const currentFlags = parseFilterFlags(searchParams.get(PARAM_NAME) || '')

      if (currentFlags.includes(flag)) {
        setFlags(currentFlags.filter(x => x !== flag))
      } else {
        setFlags([...currentFlags, flag])
      }
    },
    [searchParams, setFlags]
  )

  return {
    filters,
    setFilters,
    hasFilters,
    clearFilters,
    toggleFilterFlag,
  }
}
