import React, { useEffect } from 'react'

import img from '@sketch/icons/workspace-thank-you.png'
import img2x from '@sketch/icons/workspace-thank-you@2x.png'

import { useToast } from '@sketch/toasts'
import {
  useFlag,
  useAnalytics,
  IndexLayoutOldTitle,
  IndexLayoutOldSubtitle,
  routes,
  IndexLayoutExtraProps,
  useUserProfile,
  RouteProps,
  IndexLayoutHeaderLink,
  IndexLayoutContent,
} from '@sketch/modules-common'

import {
  Wrapper,
  TextInbox as Text,
  DoThisLaterButton,
  ResendButton,
  BackgroundImage,
  FooterWrapper,
  StyledFooter,
} from './CheckYourInboxView.styles'

import { useResendVerificationEmailMutation } from '@sketch/gql-types'
import { Link } from '@sketch/components'
import { SKETCH_WEBSITE } from '@sketch/env-config'

interface CheckYourInboxViewProps
  extends RouteProps<'CHECK_YOUR_INBOX'>,
    IndexLayoutExtraProps {}

export const CheckYourInboxView = (props: CheckYourInboxViewProps) => {
  const { HeaderPortal, history, useOverrideLayoutProps } = props

  const { showToast } = useToast()
  const { trackEvent } = useAnalytics()

  useOverrideLayoutProps({
    showFooter: false,
  })

  // TODO: Remove usage of mandatory-email-verification when release to production
  // https://github.com/sketch-hq/Cloud/issues/11277
  const isMandatoryEmailVerification = useFlag('mandatory-email-verification')
  const { data } = useUserProfile()

  // Redirect already verified users to the entry route
  useEffect(() => {
    if (data?.me.isVerified) history.push(routes.ENTRY.create({}))
  }, [data, history])

  // Operations
  const [resendVerificationEmail, { loading }] =
    useResendVerificationEmailMutation({
      redirectErrors: true,
      onCompleted: () =>
        showToast(`Verification email sent — check your inbox`),
      onError: 'show-toast',
    })

  return (
    <IndexLayoutContent center paddingHorizontal>
      <HeaderPortal>
        <IndexLayoutHeaderLink headerLink="check-inbox" />
      </HeaderPortal>
      <Wrapper>
        <IndexLayoutOldTitle>Check your Inbox</IndexLayoutOldTitle>
        <IndexLayoutOldSubtitle>
          Follow the link we sent to <strong>{data?.me.email}</strong> to
          confirm your account.
        </IndexLayoutOldSubtitle>
        <ResendButton
          variant="primary"
          size="32"
          loading={loading}
          onClick={() => {
            trackEvent('ONBOARDING - resend verify email', {
              source: 'check your inbox page',
            })
            resendVerificationEmail()
          }}
        >
          Resend Email
        </ResendButton>
        <Text>
          <span>
            Need Help?{' '}
            <Link
              variant="primary"
              href={`${SKETCH_WEBSITE}/support/`}
              target="_blank"
              external
              isUnderlined
            >
              Get in touch
            </Link>{' '}
          </span>
        </Text>
        {!isMandatoryEmailVerification && (
          <DoThisLaterButton
            to={routes.WORKSPACE_AGNOSTIC_DISCOVER.create({})}
            variant="primary-untinted"
            onClick={() => {
              trackEvent('ONBOARDING - skip email verification')
            }}
          >
            Do This Later
          </DoThisLaterButton>
        )}
      </Wrapper>
      <FooterWrapper>
        <BackgroundImage
          srcSet={`${img} 1x, ${img2x} 2x`}
          alt="Sketch app screenshot"
        />
        <StyledFooter />
      </FooterWrapper>
    </IndexLayoutContent>
  )
}
