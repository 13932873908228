import React from 'react'

import { useSearchFiltersOld } from '../../../shares/hooks/useSearchFiltersOld'
import { Button } from '@sketch/components'

import EmptyState from '../../../shares/components/EmptyStateOld'
import DocumentListEmptyState from '../../../shares/components/DocumentListEmptyState'

interface CollectionEmptyStateProps {
  onAddDocumentClick: () => void
  userCanManageCollection?: boolean
  search?: string
}

const CollectionEmptyState = (props: CollectionEmptyStateProps) => {
  const { onAddDocumentClick, search, userCanManageCollection } = props
  const { filters } = useSearchFiltersOld()

  const action = userCanManageCollection && (
    <Button buttonStyle="primary-32" onClick={onAddDocumentClick}>
      Add Documents...
    </Button>
  )

  /**
   * If there's a search result we default to the old layout with the icon
   * https://sketch.slack.com/archives/C04N2EPHUGN/p1680696902971989?thread_ts=1680696398.697479&cid=C04N2EPHUGN
   */
  if (search || filters.length > 0) {
    return (
      <EmptyState
        title="No documents added to collection yet"
        description="Choose a document you want to add to the collection, then select Move > To Collection from the document menu."
        icon={'documentPlaceholder'}
        callToAction={action}
        search={search}
        filters={filters}
      />
    )
  }

  return (
    <DocumentListEmptyState
      title="No documents added to collection yet"
      description="Choose a document you want to add to the collection, then select Move > To Collection from the document menu."
      editorialContent="empty-state-collection"
      actions={action}
    />
  )
}

export default CollectionEmptyState
