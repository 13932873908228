import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { CWV_ROUTES, isDocumentPrototypeRoute } from '@sketch/modules-common'

import { useSearch } from '@sketch/utils'
import {
  MagIcon,
  SearchField,
  SearchButton,
  Wrapper,
} from './DocumentNavbar.styles'

const getPlaceholderByPath = (path: string) => {
  if (CWV_ROUTES.includes(path)) {
    return 'Search Components'
  } else if (isDocumentPrototypeRoute(path)) {
    return 'Search Prototypes'
  }

  return 'Search Artboards'
}

interface DocumentNavbarProps
  extends Pick<React.ComponentProps<'input'>, 'onBlur' | 'onFocus'> {
  forceExpand?: boolean
}

const DocumentNavbar = (props: DocumentNavbarProps) => {
  const { path } = useRouteMatch()
  // Search query param, shared for both artboards and components
  const { search, setSearch: setSearchRaw } = useSearch()

  const [expanded, setExpanded] = useState(false)
  const [isSearchFocused, setIsSearchFocused] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const isExpandedInRoutes =
    CWV_ROUTES.includes(path) || isDocumentPrototypeRoute(path)

  const isInputExpanded = Boolean(props.forceExpand || expanded || search)

  const setSearch = useCallback(
    (value: string) => {
      const isInputEmpty = value.length === 0

      // Avoid search when value is empty and field is focused
      if (isInputEmpty && isSearchFocused) {
        return
      }

      setSearchRaw(value)
    },
    [isSearchFocused, setSearchRaw]
  )

  // When the users unfocuses the search input and it has no value
  // we clear the search value
  useEffect(() => {
    if (!isSearchFocused && isInputExpanded && inputRef.current?.value === '') {
      setSearchRaw('')
    }
  }, [isInputExpanded, isSearchFocused, setSearch, setSearchRaw])

  // Force focus on the input when it's mounted
  useEffect(() => {
    isInputExpanded && inputRef.current?.focus()
  }, [isInputExpanded])

  // Expand input in these routes
  useEffect(() => {
    if (isExpandedInRoutes) {
      setExpanded(true)
    }
  }, [isExpandedInRoutes, path])

  useEffect(() => {
    if (!isInputExpanded || isExpandedInRoutes) {
      return
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (
        event.target instanceof HTMLElement &&
        inputRef.current?.contains(event.target)
      ) {
        return
      }

      setExpanded(false)
    }

    window.addEventListener('click', handleClickOutside)

    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [isExpandedInRoutes, isInputExpanded])

  return (
    <Wrapper $isExpanded={isInputExpanded}>
      <SearchButton
        onClick={() => setExpanded(true)}
        size="32"
        data-testid="search-toogle"
      >
        <MagIcon />
      </SearchButton>
      <SearchField
        ref={inputRef}
        placeholder={getPlaceholderByPath(path)}
        onChange={setSearch}
        value={search}
        onFocus={() => {
          setIsSearchFocused(true)
        }}
        onBlur={() => {
          setIsSearchFocused(false)
        }}
        {...props}
      />
    </Wrapper>
  )
}

export default DocumentNavbar
