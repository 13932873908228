import styled, { css } from 'styled-components'

import { ReactComponent as ResetIcon } from '@sketch/icons/clockwise-arrow-circle-16'
import { ReactComponent as FilterIconSvg } from '@sketch/icons/3-lines-filter-24'

import {
  Checkbox,
  ButtonUnstyled,
  Dropdown,
  Navbar,
  SelectPopover,
  popoverStyles,
} from '@sketch/components'

export const StyledSelectPopover = styled(SelectPopover)`
  &[data-reach-listbox-popover] {
    ${popoverStyles}
    width: 270px;
    padding-left: 0;
    padding-right: 0;
  }
`

export const StyledCheckbox = styled(Checkbox)`
  cursor: pointer;
  padding: 8px 12px 8px 12px;
`

export const StyledDropdownItem = styled(Dropdown.Item)`
  padding: 0;
`

export const StyledDropdownResetItem = styled(Dropdown.Item)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px 8px 12px;
`

export const Filter = styled(Navbar.Filter)`
  input[type='search'] {
    /* Padding right includes the clear button and the options button */
    padding-right: 32px; /* stylelint-disable-line scales/space */
    outline-offset: -4px; /* To avoid cutting focus ring */
  }
`

export const StyledDropdownHeader = styled(Dropdown.Header)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 20px;
  pointer-events: auto;
`

export const IconButton = styled(ButtonUnstyled)`
  cursor: pointer;
`

export const StyledResetIcon = styled(ResetIcon)<{ $active: boolean }>`
  width: 16px;
  height: 16px;
  color: ${({ $active, theme }) =>
    $active
      ? theme.colors.foreground.secondary.A
      : theme.colors.state.disabled};
`

export const FilterWrapper = styled.div`
  margin-left: 8px;
`

export const FilterIcon = styled(FilterIconSvg)<{ $isActive?: boolean }>`
  color: ${({ theme }) => theme.colors.foreground.secondary.D};
  height: 24px;
  width: 24px;
`

export const FilterButton = styled(ButtonUnstyled)<{ $isActive?: boolean }>`
  cursor: pointer;
  width: 40px;
  height: 32px;

  padding: 4px 8px;
  border-radius: ${({ theme }) => theme.radii.large};
  color: ${({ theme }) => theme.colors.background.secondary.A};

  &:hover {
    ${({ $isActive, theme }) =>
      !$isActive &&
      css`
        background-color: ${theme.colors.state.hover};

        ${FilterIcon} {
          color: ${theme.colors.foreground.secondary.A};
        }
      `}
  }

  ${({ $isActive, theme }) =>
    $isActive &&
    css`
      background-color: ${theme.colors.sketch.F};

      ${FilterIcon} {
        color: ${theme.colors.sketch.A};
      }
    `}
`
