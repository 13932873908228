import React from 'react'

import LegacyEmptyState from '../EmptyStateOld'
import DocumentListEmptyState from '../DocumentListEmptyState'

const TITLE = 'Trash is empty'

interface TrashListEmptyStateProps {
  search?: string
}

const TrashListEmptyState = ({ search }: TrashListEmptyStateProps) => {
  if (search) {
    return (
      <LegacyEmptyState
        title={TITLE}
        description="Documents and projects in Trash are permanently deleted after 90 days."
        search={search}
      />
    )
  }

  return (
    <DocumentListEmptyState
      title={TITLE}
      description="Move any unwanted documents or projects here. "
    />
  )
}

export default TrashListEmptyState
