import React from 'react'
import { useInView } from 'react-intersection-observer'

import { selectors } from '@sketch/constants'

import { PageBeginnerMarker, StyledHeader } from './Header.styles'
import { useForTablet } from '../Breakpoint'

const HEADER_SLIM_PORTAL_ID = 'header-slim'

export const useMobileScrolled = () => {
  const [isScrolled, setIsScrolled] = React.useState(false)
  const isTabletAndBigger = useForTablet()
  const isMobile = !isTabletAndBigger

  React.useEffect(() => {
    const scrollingContainer = document.querySelector(
      selectors.contentScrollable
    )

    // Only run this effect on mobile
    if (isMobile && scrollingContainer) {
      const handleScroll = () => {
        setIsScrolled(scrollingContainer.scrollTop > 10)
      }

      scrollingContainer.addEventListener('scroll', handleScroll, {
        capture: true,
      })

      return () => {
        scrollingContainer.removeEventListener('scroll', handleScroll, {
          capture: true,
        })
      }
    }
  }, [isMobile])

  return isScrolled
}

const useShouldShowStickyHeader = () => {
  // We only want to show the slim header when the portal exists
  const [shouldShowStickyHeader, setShouldShowStickyHeader] =
    React.useState(false)

  // We only want to show the slim header for tablet and desktop views
  const isTabletAndBigger = useForTablet()

  React.useEffect(() => {
    setShouldShowStickyHeader(
      !!document.querySelector(`[data-portal-id="${HEADER_SLIM_PORTAL_ID}"]`)
    )
  }, [])

  return isTabletAndBigger && shouldShowStickyHeader
}

export const Header: React.FC = ({ children }) => {
  const { ref, inView } = useInView({
    initialInView: true,
  })

  const { ref: markerRef, inView: beginnerMarker } = useInView({
    initialInView: true,
  })

  const shouldShowStickyHeader = useShouldShowStickyHeader()
  const headerSticky = !inView && shouldShowStickyHeader
  const showBackground = !beginnerMarker && shouldShowStickyHeader
  const mobileScrolled = useMobileScrolled()

  return (
    <>
      <PageBeginnerMarker ref={markerRef} />
      <StyledHeader
        ref={ref}
        $sticky={headerSticky}
        $background={showBackground || headerSticky}
        $mobileScrolled={mobileScrolled}
      >
        {children}
      </StyledHeader>
    </>
  )
}
