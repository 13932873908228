import React, { useEffect, useRef } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { InView } from 'react-intersection-observer'

import { ResponsiveValues } from '@sketch/global-styles'

import { clamp } from '@sketch/utils'

import { useFlag, routes, useUserProfile } from '@sketch/modules-common'
import { useShareListItemDragDrop } from '../../hooks/useShareDragAndDrop'

import {
  Breakpoint,
  Table,
  AspectRatioGrid,
  pluralize,
} from '@sketch/components'
import EmptyState from '../EmptyStateOld'

import ShareListItemDrag from '../ShareListItemDrag'

import {
  DocumentGridItemPlaceholder,
  DocumentTableItemPlaceholder,
  RenderSharesDropdown,
  ConnectedDocumentItem,
} from '../DocumentItem'

import {
  ShareListItemFragment,
  useGetDocumentListSettingsQuery,
} from '@sketch/gql-types'

import {
  ActionTableCell,
  Footer,
  TableWrapper,
  FocusLink,
  HeaderTableCell,
} from './DocumentItemsLayout.styles'

interface CustomColumns {
  customColumns?: ResponsiveValues<number>
}

interface DocumentItemsLayoutProps<S> extends CustomColumns {
  workspaceId: string
  shares: S[]
  showProjectName: boolean
  showCollectionName?: boolean
  onLoadMore: () => Promise<any>
  totalCount: number
  renderDropdown: RenderSharesDropdown<S>
  action?: (share: S) => (() => void) | 'link'
  search?: string
  canUseDesignSystemManager?: boolean
  projectId?: string
}

const COLUMNS: ResponsiveValues<number> = [2, 2, 3, 3, 3, 4, 5, 6]
const GUTTER: ResponsiveValues<number> = [16, 16, 16, 16, 24, 24, 24]
const VERTICAL_SPACE: ResponsiveValues<number> = [24, 24, 24, 24, 24, 24, 24]
const PAGE_SIZE = 20

const buildPlaceholderArray = (remainingSharesToLoad: number) =>
  Array(clamp(remainingSharesToLoad, 0, PAGE_SIZE))

interface DraggableListItemProps<S> {
  workspaceId: string
  share: S
  isMobile: boolean
  action: (() => void) | 'link'
  renderDropdown: RenderSharesDropdown<S>
  showProjectName?: boolean
  showCollectionName?: boolean
  userIdentifier?: string
}

const DraggableListItem = <S extends ShareListItemFragment>(
  props: DraggableListItemProps<S>
) => {
  const {
    workspaceId,
    share,
    isMobile,
    action,
    renderDropdown,
    showProjectName,
    showCollectionName,
    userIdentifier,
  } = props

  const disabled = isMobile || !share.userCanUpdateSettings
  const history = useHistory()

  // In the list view we don't want to be able to create collections by
  // dragging shares onto each other so we only use `draggable` and
  // `onDragStart` and ignore the `onDropped` handlers.
  const { draggable, onDragStart } = useShareListItemDragDrop({
    disabled,
    shareId: share.identifier,
    onDropped: () => undefined,
  })

  return (
    <ConnectedDocumentItem
      workspaceId={workspaceId}
      share={share}
      renderDropdown={renderDropdown}
      presentation="list"
      showProjectName={showProjectName}
      showCollectionName={showCollectionName}
      onClick={event => {
        if (action !== 'link') {
          action()
          return
        }

        const link = routes.SHARE_VIEW.create({
          shareID: share.identifier,
        })

        if (event.metaKey) {
          /* Simulate the link opening with meta key */
          window.open(link, '_blank')
        } else {
          history.push(link)
        }
      }}
      userIdentifier={userIdentifier}
      draggable={draggable}
      onDragStart={onDragStart}
    />
  )
}

const DocumentItemsLayout = <S extends ShareListItemFragment>(
  props: DocumentItemsLayoutProps<S>
) => {
  const {
    workspaceId,
    shares,
    showProjectName,
    onLoadMore,
    action: actionCreator = () => 'link',
    totalCount,
    renderDropdown,
    search,
    customColumns,
    projectId,
    showCollectionName,
  }: typeof props = props

  const isDesignSystemsOn = useFlag('design-systems')

  const isWorkspaceLibrariesRoute = useRouteMatch({
    path: routes.WORKSPACE_LIBRARIES.template(),
    exact: true,
  })

  // Make sure the onLoadMore Reference is always the latest
  const onLoadMoreRef = useRef<() => Promise<any>>(onLoadMore)
  useEffect(() => {
    onLoadMoreRef.current = onLoadMore
  }, [onLoadMore])

  // Load the document layout
  const { data } = useGetDocumentListSettingsQuery()
  const { documentsLayout } = data || {}

  // Get the User Identifier to show the "You" label
  const { data: userData } = useUserProfile()
  const userIdentifier = userData?.me.identifier

  const renderGridItem = (share: S, isMobile: boolean) => {
    const action = actionCreator(share)

    if (action !== 'link') {
      return (
        <ShareListItemDrag
          projectId={projectId}
          key={share.identifier}
          shareId={share.identifier}
          disabled={isMobile || !share.userCanUpdateSettings}
        >
          <ConnectedDocumentItem
            workspaceId={workspaceId}
            key={share.identifier}
            share={share}
            renderDropdown={renderDropdown}
            presentation="grid"
            showProjectName={showProjectName}
            showCollectionName={showCollectionName}
            onClick={() => action()}
          />
        </ShareListItemDrag>
      )
    }

    return (
      <ShareListItemDrag
        projectId={projectId}
        key={share.identifier}
        shareId={share.identifier}
        disabled={isMobile || !share.userCanUpdateSettings}
      >
        <FocusLink
          draggable={false}
          to={{
            pathname: routes.SHARE_VIEW.create({ shareID: share.identifier }),
            state: { componentsView: true },
          }}
        >
          <ConnectedDocumentItem
            key={share.identifier}
            workspaceId={workspaceId}
            share={share}
            renderDropdown={renderDropdown}
            presentation="grid"
            showProjectName={showProjectName}
            showCollectionName={showCollectionName}
            userIdentifier={userIdentifier}
          />
        </FocusLink>
      </ShareListItemDrag>
    )
  }

  const renderTableItem = (share: S, isMobile: boolean) => {
    const action = actionCreator(share)

    return (
      <DraggableListItem
        workspaceId={workspaceId}
        action={action}
        renderDropdown={renderDropdown}
        showProjectName={showProjectName}
        showCollectionName={showCollectionName}
        userIdentifier={userIdentifier}
        share={share}
        isMobile={isMobile}
      />
    )
  }

  const renderLayout = (
    isMobile: boolean,
    customColumns?: ResponsiveValues<number>
  ) => {
    const placeholders = buildPlaceholderArray(
      (totalCount || 0) - shares.length
    )

    // for design systems search: check if we're on the library route and if empty count for a special message
    if (isWorkspaceLibrariesRoute && totalCount === 0 && isDesignSystemsOn) {
      return (
        <EmptyState
          search={search}
          title="No Libraries"
          description="No Libraries added yet."
          icon="link"
        />
      )
    }

    // Merge the items with the placeholders have them rendered together
    // This prevents the page from bumping when the placeholders are replaced
    // with actual items
    const sharesAndPlaceholders = [...shares, ...placeholders]

    // Create the InView listener warn when it starts showing the placeholders, this will load the next page
    const inViewListener =
      placeholders.length > 0 ? (
        <InView onChange={inView => inView && onLoadMoreRef.current?.()}>
          <span className="sr-only">Loading Placeholder</span>
        </InView>
      ) : null

    if (documentsLayout === 'LIST') {
      const headerLabel = pluralize('Document', 'Documents', totalCount)

      return (
        <TableWrapper
          $hideProjects={!showProjectName}
          $hideCollections={!showCollectionName}
        >
          <Table
            header={[
              {
                label: `${totalCount} ${headerLabel}`,
                customCell: HeaderTableCell,
              },
              { label: 'Project', customCell: HeaderTableCell },
              { label: 'Collection', customCell: HeaderTableCell },
              { label: 'Last Updated', customCell: HeaderTableCell },
              { label: 'Updated By', customCell: HeaderTableCell },
              { label: '', customCell: ActionTableCell },
            ]}
            items={sharesAndPlaceholders}
            renderItemKey={share =>
              // if it's not a share the table will generate a index based key
              typeof share === 'object' ? share.identifier : undefined
            }
            renderItem={item => {
              if (typeof item === 'object') {
                return renderTableItem(item, isMobile)
              }

              return (
                <DocumentTableItemPlaceholder inViewListener={inViewListener} />
              )
            }}
            evenColumns={!isMobile}
          />
        </TableWrapper>
      )
    } else {
      return (
        <AspectRatioGrid
          columns={customColumns ?? COLUMNS}
          verticalRowSpace={VERTICAL_SPACE}
          gutterSize={GUTTER}
        >
          {sharesAndPlaceholders.map((item, index) => {
            if (typeof item === 'object') {
              return renderGridItem(item, isMobile)
            }

            return (
              <DocumentGridItemPlaceholder
                key={index}
                inViewListener={inViewListener}
              />
            )
          })}
        </AspectRatioGrid>
      )
    }
  }

  return (
    <>
      <Breakpoint on="sm">
        {matches => renderLayout(!matches, customColumns)}
      </Breakpoint>
      <Footer />
    </>
  )
}

export default DocumentItemsLayout
