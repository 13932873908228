import React from 'react'
import styled from 'styled-components'

import { shortcuts } from '@sketch/utils'
import { Tooltip, useBreakpoint } from '@sketch/components'

import { ReactComponent as ChatBubble } from '@sketch/icons/chat-bubble-add-24'

import {
  useAnnotationOverlayContext,
  usePlaceDraftAnnotation,
} from '../../hooks'
import { AddButton } from './AddAnnotationButton.styles'
import { useToggleSidebarTab } from '../../../shares/components/ShareSidebarTabContext'

export interface AddAnnotationButtonProps {
  disabled?: boolean
  toolbar?: boolean
}

const AddAnnotationButton: React.FC<AddAnnotationButtonProps> = ({
  disabled,
  children,
  toolbar,
  ...rest
}) => {
  const { toggle } = useToggleSidebarTab()
  const isMobile = !useBreakpoint('xs')

  const annotationOverlayContext = useAnnotationOverlayContext()
  const [placeDraftAnnotation, setPlaceDraftAnnotation] =
    usePlaceDraftAnnotation() || []

  return (
    <Tooltip
      content={
        annotationOverlayContext?.isViewingLatestVersion ? (
          <>
            Add a comment{' '}
            <Tooltip.Shortcut>
              Press {shortcuts.addComment.toUpperCase()}
            </Tooltip.Shortcut>
          </>
        ) : (
          <>You can’t add a comment on older document versions.</>
        )
      }
      disableWhenTouchDevice
      placement="bottom"
    >
      <AddButton
        variant={toolbar ? 'secondary-untinted' : 'primary'}
        $toolbar={toolbar}
        size="32"
        data-testid="add-annotation"
        icon={children && ChatBubble}
        disabled={
          Boolean(disabled) ||
          Boolean(placeDraftAnnotation) ||
          !annotationOverlayContext?.isViewingLatestVersion
        }
        {...rest}
        onClick={() => {
          setPlaceDraftAnnotation?.(true)
          isMobile && toggle()
        }}
      >
        {children || <ChatBubble />}
        <span className="sr-only">New comment</span>
      </AddButton>
    </Tooltip>
  )
}

export default styled(AddAnnotationButton)``
