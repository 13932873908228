import React, { FC } from 'react'
import { Redirect, useLocation } from 'react-router'

import { routes } from '@sketch/modules-common'

import { ErrorHandler } from '@sketch/tracing'

import {
  DocumentHead,
  LoadingState,
  Pill,
  useModalContext,
  useForTablet,
  Tabs,
  Tab,
  TabList,
  TabPanel,
} from '@sketch/components'
import DocumentItemsLayout from '../../../shares/components/DocumentItemsLayout'
import { Footer } from '../../../shares/components/DocumentItemsLayout/DocumentItemsLayout.styles'
import { DocumentItemDropdown } from '../../../shares/components/DocumentItemDropdown'
import { ShareTrashedModal } from '../../../shares/components/ShareTrashedModal'
import CollectionDropdown from '../../../collections/components/CollectionDropdown'
import CollectionItemsLayout from '../../../collections/components/CollectionItemsLayout'

import ProjectHeader from '../ProjectHeader'

import ProjectEmptyState from '../ProjectEmptyState'
import ProjectCollectionError from '../ProjectCollectionError'
import {
  HeaderAfterCollections,
  DocumentsWrapper,
  TabPanelContent,
  MobileWrapper,
} from './ProjectShares.styles'

import { useClearUrlQuery, useSearch } from '@sketch/utils'
import { useSearchFiltersOld } from '../../../shares/hooks/useSearchFiltersOld'
import { useProject } from '../../hooks/useProjectOld/useProjectOld'

import {
  WorkspaceMinimalFragment,
  useGetDocumentListSettingsQuery,
  ShareListItemFragment,
} from '@sketch/gql-types'
import { useSortingSettings } from '../../../shares/hooks/useSortSettings'
import { ScrollToTop } from '../../../../components/ScrollToTopButton'
import { useHideCollectionsSetting } from '../../../collections/hooks'

export interface ProjectSharesProps {
  projectId: string
  workspace: WorkspaceMinimalFragment
}

interface ProjectDocumentListProps {
  projectId: string
  workspace: WorkspaceMinimalFragment
  totalCount: number
  onLoadMore: () => Promise<any>
  shares: ShareListItemFragment[]
}

const ProjectDocumentList: FC<ProjectDocumentListProps> = ({
  workspace,
  projectId,
  totalCount,
  onLoadMore,
  shares,
}) => {
  const { showModal } = useModalContext()
  const { hideCollections } = useHideCollectionsSetting()
  const { sort } = useSortingSettings()
  const queryVariables = {
    shortId: projectId,
    after: null,
    search: {
      name: null,
      isCurrentVersionDownloadable: null,
      filters: ['NO_COLLECTION'],
    },
    sortOrder: sort,
  }

  return (
    <DocumentItemsLayout
      projectId={projectId}
      shares={shares}
      workspaceId={workspace.identifier}
      showProjectName={false}
      showCollectionName={hideCollections}
      totalCount={totalCount}
      onLoadMore={onLoadMore}
      renderDropdown={item => {
        // Rendering a Share Item
        const share = shares.find(share => share.identifier === item.identifier)

        if (!share) {
          ErrorHandler.shouldNeverHappen(
            'share should always be present rendering the ProjectDocumentDropdown'
          )
          return null
        }

        return (
          <DocumentItemDropdown
            share={share}
            workspaceIdentifier={workspace.identifier}
            queryVariables={queryVariables}
            workspaceStatus={workspace.status}
          />
        )
      }}
      action={item => {
        // This action is triggered when the user clicks the DocumentItem
        const isShare = item.__typename === 'Share'
        if (isShare && item.deletedAt) {
          return () => showModal(ShareTrashedModal, { share: item })
        }

        return 'link'
      }}
    />
  )
}

const ProjectShares: FC<ProjectSharesProps> = props => {
  const { workspace, projectId } = props
  const location = useLocation()
  const { searchDebounced: search } = useSearch()
  const { filters } = useSearchFiltersOld()
  const hasFilters = !!(search || filters.length > 0)

  const { clearUrlQuery } = useClearUrlQuery()
  const handleClearFilters = () => {
    clearUrlQuery(['filter', 'filters', 'search'])
  }

  const isTabletAndBigger = useForTablet()
  const isMobile = !isTabletAndBigger

  const { identifier: workspaceId } = workspace

  const { data, loading, error, loadMore } = useProject({
    workspaceId,
    projectId,
    search,
    filters,
  })

  // Load the document layout
  const { data: documentListSettingsData } = useGetDocumentListSettingsQuery()
  const { documentsLayout } = documentListSettingsData || {}

  const isCurrentlyInProjectView =
    location.pathname ===
    routes.WORKSPACE_PROJECT.create({ workspaceId, projectId })

  const isCurrentlyInTrashProjectView =
    location.pathname ===
    routes.WORKSPACE_TRASH_PROJECT.create({ workspaceId, projectId })

  if (loading && !data.project) {
    return <LoadingState />
  }

  if (error) {
    return (
      <ProjectCollectionError
        projectId={projectId}
        error={error}
        type="project"
      />
    )
  }

  const project = data.project
  const collections = data.collections?.entries ?? []

  const shares = project?.shares.entries ?? []
  const hasCollections = !!collections.length
  const hasShares = !!shares.length

  const totalCount = {
    shares: project?.shares.meta.totalCount ?? 0,
    collections: data.collections?.meta.totalCount ?? 0,
  }

  /* ==========
   * Handle redirects when trashing / restoring projects
   * ==========
   * > Redirect to the Trash Project View if the project has been soft-deleted */

  if (!!project?.deletedAt && isCurrentlyInProjectView) {
    return (
      <Redirect
        to={routes.WORKSPACE_TRASH_PROJECT.create({
          projectId,
          workspaceId,
        })}
        push={false}
      />
    )
  }

  /*
   * > Redirect to the Project View if the project was restored */
  if (!project?.deletedAt && isCurrentlyInTrashProjectView) {
    return (
      <Redirect
        to={routes.WORKSPACE_PROJECT.create({
          projectId,
          workspaceId,
        })}
        push={false}
      />
    )
  }
  /* ==========
   * ========== */

  const collectionsCount = collections.length

  // Project Empty State
  if (!loading && project && shares.length === 0 && collectionsCount === 0) {
    return (
      <ProjectEmptyState
        workspace={workspace}
        project={project}
        breadcrumbs={undefined}
        hasFilters={hasFilters}
        onClearFilters={handleClearFilters}
      />
    )
  }

  const renderDocumentsList = () => {
    if (!hasShares) {
      return null
    }

    if (hasCollections) {
      const isGridLayout = documentsLayout === 'GRID'

      return (
        <DocumentsWrapper listLayout={!isGridLayout}>
          {/**
           * This header should only be seen when the user has both shares and
           * collections and is on the grid view
           */}
          {isGridLayout && isTabletAndBigger && (
            <HeaderAfterCollections>
              Documents
              <Pill variant="secondary">{totalCount.shares}</Pill>
            </HeaderAfterCollections>
          )}
          <ProjectDocumentList
            projectId={projectId}
            workspace={workspace}
            shares={shares}
            totalCount={totalCount.shares}
            onLoadMore={loadMore.project}
          />
        </DocumentsWrapper>
      )
    }

    // return list without gradient wrapper when there's no collections
    return (
      <ProjectDocumentList
        projectId={projectId}
        workspace={workspace}
        shares={shares}
        totalCount={totalCount.shares}
        onLoadMore={loadMore.project}
      />
    )
  }

  const renderCollectionsList = () => {
    if (!hasCollections) return null

    return (
      <>
        <CollectionItemsLayout
          workspaceId={workspaceId}
          projectId={projectId}
          collections={collections}
          onLoadMore={loadMore.collections}
          totalCount={totalCount.collections}
          renderDropdown={item => {
            const collection = collections.find(
              collection => collection.identifier === item.identifier
            )

            if (!collection) {
              ErrorHandler.shouldNeverHappen(
                'share should always be present rendering the ProjectDocumentDropdown'
              )
              return null
            }

            return (
              <CollectionDropdown
                workspace={workspace}
                collection={collection}
                project={project!}
              />
            )
          }}
        />
        <ScrollToTop />
      </>
    )
  }

  // For mobile view we want to show tabs
  if (isMobile && hasCollections) {
    return (
      <MobileWrapper>
        <DocumentHead title={project?.name} />
        {project && (
          <ProjectHeader
            project={project}
            workspace={workspace}
            breadcrumbs={undefined}
            workspaceAccessLevel={project?.workspaceAccessLevel!}
          />
        )}
        <Tabs>
          <TabList>
            <Tab>
              Collections{' '}
              {collectionsCount && (
                <Pill variant="secondary">{collectionsCount}</Pill>
              )}
            </Tab>
            <Tab>
              Documents{' '}
              {totalCount.shares && (
                <Pill variant="secondary">{totalCount.shares}</Pill>
              )}
            </Tab>
          </TabList>
          <TabPanel>
            <TabPanelContent>
              {renderCollectionsList()}
              <Footer />
            </TabPanelContent>
          </TabPanel>
          <TabPanel>
            <TabPanelContent>{renderDocumentsList()}</TabPanelContent>
          </TabPanel>
        </Tabs>
      </MobileWrapper>
    )
  }

  return (
    <>
      <DocumentHead title={project?.name} />
      {project && (
        <ProjectHeader
          project={project}
          workspace={workspace}
          breadcrumbs={undefined}
          workspaceAccessLevel={project?.workspaceAccessLevel!}
        />
      )}
      {renderCollectionsList()}
      {renderDocumentsList()}
    </>
  )
}

export default ProjectShares
