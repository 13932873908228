import styled from 'styled-components'
import { Text, BaseButton } from '@sketch/components'

export const PageFiller = styled.div`
  flex: 1;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const Wrapper = styled.section`
  margin: 16px;

  text-align: center;
  max-width: 540px;
`

export const IconWrapper = styled.div`
  /* This color should be "Light/colors.foreground.secondary.4" but given the icon composition */
  /* Opacity needs to be applied to the whole object */
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  opacity: 0.55;

  width: 64px;
  height: 64px;

  margin: 0 auto 16px;
`

export const Title = styled(Text.H1).attrs({
  textStyle: 'copy.primary.standard.F',
})`
  margin: 0;
  line-height: ${({ theme }) => theme.lineHeights.default};
  font-weight: 500;
`

export const Description = styled(Text).attrs({
  textStyle: 'copy.quaternary.standard.E',
})`
  margin: 0 auto 16px;
`
export const CallToActionWrapper = styled.div`
  margin: 0 auto;

  display: flex;
  flex-wrap: wrap;
  gap: 8px 16px;
  justify-content: center;

  /*
    This fix was added to prevent the button spacing set on 
    https://github.com/sketch-hq/cloud-frontend/blob/ede873446ea23636e1754aeef05f9b98bee67281/packages/components/src/Button/Button.styles.ts#L61-L65

    To not be applied here, the flex will set the correct spacing
  */
  ${/* sc-selector */ BaseButton} + button, ${
    /* sc-selector */ BaseButton
  } + a {
    margin: 0;
  }
`
