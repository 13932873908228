import React, { useState } from 'react'

import {
  Button,
  ErrorMessage,
  Filter,
  Section,
  pluralize,
  useModalContext,
  handleFetchMore,
  LoadPageSeparator,
  TableComponents,
  useResponsiveDropdown,
} from '@sketch/components'

import {
  WorkspaceMinimalFragment,
  WorkspacePermissionGroupFragment,
  useGetWorkspacePermissionGroupsQuery,
} from '@sketch/gql-types'
import { PermissionGroupItem } from '@sketch/modules-common'

import { dataIdFromObject } from '@sketch/graphql-cache'

import EmptyState from '../../../shares/components/EmptyStateOld'

import AddPermissionGroupModal from '../../modals/AddPermissionGroupModal'
import PermissionGroupOptions from '../../components/PermissionGroupOptions'

import {
  EmptyStateWrapper,
  ListWrapper,
  Table,
  OptionsButton,
  OptionsIcon,
  MinimumTableCell,
  SkeletonAvatar,
  SkeletonName,
  MaxWidth,
} from './WorkspaceSettingsPermissionGroups.styles'

const ENTRIES_PATH = ['workspace', 'permissionGroups', 'entries']
const TABLE_HEADER = [{ label: 'Group Name' }, { label: 'Group Options' }]

interface TableItemProps {
  permissionGroup: WorkspacePermissionGroupFragment
  workspaceIdentifier: string
}

const TableItem = (props: TableItemProps) => {
  const { permissionGroup, workspaceIdentifier } = props

  const [content, buttonProps] = useResponsiveDropdown({
    dropdown: PermissionGroupOptions,
    dropdownProps: { permissionGroup, workspaceIdentifier },
    placement: 'bottom-end',
  })

  return (
    <TableComponents.TableRow data-testid="group-table-row">
      <TableComponents.TableCell>
        <PermissionGroupItem permissionGroup={permissionGroup} />
      </TableComponents.TableCell>
      <MinimumTableCell>
        <OptionsButton variant="secondary" size="32" {...buttonProps}>
          <OptionsIcon /> <span className="sr-only">Group options</span>
        </OptionsButton>
        {content}
      </MinimumTableCell>
    </TableComponents.TableRow>
  )
}

interface WorkspaceSettingsPermissionGroupsProps {
  workspace: WorkspaceMinimalFragment
}

const WorkspaceSettingsPermissionGroups = (
  props: WorkspaceSettingsPermissionGroupsProps
) => {
  const { workspace } = props

  const [search, setSearch] = useState('')
  const { showModal } = useModalContext()

  const workspaceIdentifier = workspace.identifier

  const { data, loading, error, fetchMore } =
    useGetWorkspacePermissionGroupsQuery({
      variables: {
        workspaceIdentifier,
        search,
      },
    })

  const after = data?.workspace.permissionGroups.meta.after
  const groups = data?.workspace.permissionGroups.entries || []

  const groupCount =
    data?.workspace.totalWorkspacePermissionGroups.meta.totalCount

  const getGroupTitleCopy = () => {
    if (groupCount === 0 || !groupCount) {
      return 'Groups'
    }

    return `${groupCount} ${pluralize('Group', 'Groups', groupCount)} in ${
      workspace.name
    }`
  }

  let content = null
  if (loading) {
    content = (
      <>
        <Table
          hideHeader
          header={TABLE_HEADER}
          items={Array.from({ length: 2 })}
          renderItem={group => (
            <TableComponents.TableRow>
              <TableComponents.TableCell>
                <SkeletonAvatar />
                <SkeletonName />
              </TableComponents.TableCell>
              <TableComponents.TableCell />
            </TableComponents.TableRow>
          )}
        />
      </>
    )
  } else if (error) {
    content = <ErrorMessage.Generic />
  } else if (groupCount === 0) {
    content = (
      <EmptyStateWrapper>
        <EmptyState
          title="No groups created yet"
          icon="group-circle"
          description="Create a group and add some Workspace Members."
        />
      </EmptyStateWrapper>
    )
  } else if (groups.length === 0) {
    content = (
      <EmptyStateWrapper>
        <EmptyState
          title="No groups matched your filter"
          icon="search"
          description="Try adjusting your search to find the groups you're looking for"
        />
      </EmptyStateWrapper>
    )
  } else {
    content = (
      <>
        <Table
          hideHeader
          header={TABLE_HEADER}
          items={groups}
          evenColumns
          renderItem={group => (
            <TableItem
              key={group.identifier}
              permissionGroup={group}
              workspaceIdentifier={workspaceIdentifier}
            />
          )}
        />
        {after && (
          <LoadPageSeparator
            key={after}
            loadNewPage={handleFetchMore(fetchMore, ENTRIES_PATH, {
              dataIdFromObject,
              after: after,
            })}
          />
        )}
      </>
    )
  }

  return (
    <Section
      title={getGroupTitleCopy()}
      text={
        <MaxWidth>
          Use groups to instantly give multiple Members access to specific
          projects and documents.
        </MaxWidth>
      }
      action={
        <Button
          variant="primary"
          size="40"
          onClick={() =>
            showModal(AddPermissionGroupModal, { workspaceIdentifier })
          }
        >
          Create Group…
        </Button>
      }
    >
      <Filter
        data-test-id="search-filter"
        name="search-filter"
        placeholder="Search groups…"
        onChange={setSearch}
        value={search}
      />
      <ListWrapper>{content}</ListWrapper>
    </Section>
  )
}

export default WorkspaceSettingsPermissionGroups
