export function omit<T extends object, K extends keyof T>(
  obj: T,
  keys: K[]
): Pick<T, Exclude<keyof T, K>> {
  // This should never happen, but just in case we receive falsy value - just return it back without throwing an error
  if (!obj) return obj

  const keysStr = keys.map(key => String(key))

  const result = {} as Pick<T, Exclude<keyof T, K>>

  for (const k of Object.keys(obj)) {
    const key = k as unknown as K
    if (!keysStr.includes(String(key))) {
      // eslint-disable-next-line no-extra-semi
      ;(result as any)[key] = obj[key]
    }
  }
  return result
}
