import React, { ReactNode, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { useToast } from '@sketch/toasts'

import {
  useForTablet,
  Box,
  DocumentHeader,
  useModalContext,
} from '@sketch/components'

import { ProjectHeaderActions } from './ProjectHeaderActions'

import {
  GetProjectsDocument,
  GetProjectsQueryVariables,
  useUpdateProjectMutation,
} from '@sketch/gql-types'
import { LayoutPortal } from '@sketch/utils'
import type { ProjectHeaderProps } from './types'
import { SKETCH_WEBSITE } from '@sketch/env-config'
import { RenameProject } from '../../modals'
import { ProjectsBreadcrumbsDropdown } from '../ProjectsBreadcrumbsDropdown'
import { isMyDrafts } from '../../utils'
import { EditableDescription } from './EditableDescription'
import { isDraftsRoute, isTrashRoute, useFlag } from '@sketch/modules-common'
import { userIsEditorOrAdmin } from '../../../workspace/utils'
import { workspaceSidebarLayoutSymbols } from '../../../workspace/components'

const typeInline = <T extends any>(variables: T) => variables

const DRAFTS_HEADER_COPY = {
  title: 'My Drafts',
  description:
    'Only you can see documents that you keep here, unless you share them.',
  learnMoreTooltipTitle: 'Learn more about My Drafts',
  learnMoreTooltipURL: `${SKETCH_WEBSITE}/docs/getting-started/saving-and-managing-documents/#managing-documents-in-my-drafts`,
}

const ProjectHeader: React.FC<ProjectHeaderProps> = props => {
  const { workspace, project, breadcrumbs } = props
  const { identifier: workspaceId } = workspace

  const { path } = useRouteMatch()
  const userCanRenameAndDeleteProject =
    isDraftsRoute(path) || isTrashRoute(path)
      ? false
      : userIsEditorOrAdmin(workspace)

  const { showModal } = useModalContext()

  const { showToast } = useToast()
  const isTabletAndBigger = useForTablet()
  const isMobile = !isTabletAndBigger
  const isNestedProjectsOn = useFlag('nested-projects')

  const [forceEdit, setForceEdit] = useState(false)

  const [updateProject] = useUpdateProjectMutation({
    onError: 'show-toast',
    refetchQueries: [
      {
        query: GetProjectsDocument,
        variables: typeInline<GetProjectsQueryVariables>({
          workspaceId,
        }),
      },
    ],
    awaitRefetchQueries: true,
    optimisticResponse: ({ name, description }) => ({
      __typename: 'RootMutationType',
      updateProject: {
        __typename: 'UpdateProjectResponse',
        project: {
          ...project,
          ...(name ? { name } : {}),
          ...(description ? { description } : {}),
        },
      },
    }),
    onCompleted: () => {
      isNestedProjectsOn
        ? showToast(
            <>
              &ldquo;
              <b>{project.name}</b>
              &rdquo; changes saved
            </>
          )
        : showToast('Project was renamed successfully')
    },
  })

  // Hide the Edit UI if the user doesn't have these permissions
  const handleEdit = !(userCanRenameAndDeleteProject && !project.deletedAt)
    ? undefined
    : async ({
        name,
        description,
      }: {
        name?: string
        description?: string
      }) => {
        await updateProject({
          variables: { name, identifier: project.identifier, description },
        })
      }

  const headerCopy: {
    title: string
    description?: ReactNode
    learnMoreTooltipTitle?: string
    learnMoreTooltipURL?: string
  } = isMyDrafts(project)
    ? DRAFTS_HEADER_COPY
    : {
        title: project?.name || '',
        description:
          userCanRenameAndDeleteProject && project?.description ? (
            <EditableDescription
              onSubmit={description => handleEdit?.({ description })}
              description={project.description}
            />
          ) : (
            project?.description || ''
          ),
      }

  if (isMobile) {
    return (
      <Box marginBottom={5}>
        <DocumentHeader
          title={headerCopy.title}
          description={headerCopy?.description}
          learnMoreTooltipTitle={headerCopy?.learnMoreTooltipTitle}
          learnMoreTooltipURL={headerCopy?.learnMoreTooltipURL}
          titlePlaceholder="Project name"
          actions={
            <ProjectHeaderActions {...props} onRenameProject={setForceEdit} />
          }
          onEdit={name => handleEdit?.({ name })}
          forceEdit={forceEdit}
          onClearForceEdit={() => setForceEdit(false)}
        />
      </Box>
    )
  }

  return (
    <>
      <LayoutPortal token={workspaceSidebarLayoutSymbols.header}>
        <DocumentHeader
          title={headerCopy.title}
          description={headerCopy?.description}
          learnMoreTooltipTitle={headerCopy?.learnMoreTooltipTitle}
          learnMoreTooltipURL={headerCopy?.learnMoreTooltipURL}
          titlePlaceholder="Project name"
          breadcrumb={<ProjectsBreadcrumbsDropdown data={breadcrumbs} />}
          separateBreadcrumb
          actions={
            <ProjectHeaderActions {...props} onRenameProject={setForceEdit} />
          }
          onEdit={name => handleEdit?.({ name })}
          forceEdit={forceEdit}
          onClearForceEdit={() => setForceEdit(false)}
        />
      </LayoutPortal>
      <LayoutPortal token={workspaceSidebarLayoutSymbols.headerSlim}>
        <DocumentHeader
          title={headerCopy.title}
          titlePlaceholder="Project name"
          actions={
            <ProjectHeaderActions
              {...props}
              onRenameProject={() => {
                showModal(RenameProject, {
                  projectId: project.identifier,
                  projectName: project.name,
                  workspaceId: workspace.identifier,
                })
              }}
              headerSlim
            />
          }
        />
      </LayoutPortal>
    </>
  )
}

export default ProjectHeader
