import { IndexLayoutOld, IndexLayoutOldMain } from '@sketch/modules-common'
import styled from 'styled-components'
import { transparentize } from 'polished'

import { breakpoint } from '@sketch/global-styles'
import { Link } from '@sketch/components'

export const StyledIndexLayout = styled(IndexLayoutOld)`
  position: relative;
  height: 100vh;
  overflow: hidden;

  background-color: ${({ theme }) =>
    theme.colors.syntaxHighlighting.background};

  ${/* sc-selector */ IndexLayoutOldMain} {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    padding: 0;
  }
`

export const TextWrapper = styled.div`
  max-width: 450px;
  margin: 0 auto;
  padding: 0 20px;
`

export const ActionWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 42px; /* stylelint-disable-line scales/space */
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const BackgroundImage = styled.img`
  flex: 1;
  width: 100%;
  max-width: 1024px;
  min-height: 700px;
  object-fit: cover;
  object-position: left;

  padding: 0 0 0 20px;

  ${breakpoint('md')`
    padding: 0 20px;
  `}
`

export const FooterWrapper = styled.div`
  bottom: 0px;
  padding-top: 100px; /* stylelint-disable-line scales/space */
  left: 0;
  right: 0;
  position: absolute;
  background-image: linear-gradient(
    180deg,
    ${({ theme }) => transparentize(1, theme.colors.background.secondary.B)} 0%,
    ${({ theme }) => transparentize(0.16, theme.colors.background.secondary.B)}
      50%,
    ${({ theme }) => transparentize(0, theme.colors.background.secondary.B)}
      100%
  );
`

export const ContinueLink = styled(Link)`
  margin-top: 16px;

  && {
    /* Fix the margin-left set between "button" and "a" */
    margin-left: 0;
  }
`
