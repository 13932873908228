import { useHistory, useLocation } from 'react-router-dom'

import { useURLQuerySearch } from './useURLQuerySearch'

/**
 * Custom hook to get values from URL related to the search.
 */
export const useSearch = () => {
  const history = useHistory()
  /* XXX: we use `useLocation` rather than `history.location` as
   * `history.location` is **mutable** meaning it can lead to very unexpected
   * bugs as it does not play nicely with our normal assumptions about React
   * life-cycle events.
   */
  const location = useLocation()
  const { searchDebounced, search, setSearch, setSearchImmediately } =
    useURLQuerySearch(location, history)

  return { search, searchDebounced, setSearch, setSearchImmediately }
}
