import React, {
  useRef,
  useEffect,
  useReducer,
  useLayoutEffect,
  useMemo,
} from 'react'
import { createPortal } from 'react-dom'
import { ErrorHandler } from '@sketch/tracing'
import { Observable } from '../observable'

type PortalElement = Observable<HTMLElement | null>

interface LayoutContextValue {
  reserve: (token: symbol) => void
  enroll: (token: symbol, element: HTMLElement) => void
  dismiss: (token: symbol) => void
  collectRef: (token: symbol) => PortalElement
}

const defaultLayoutPortalValue = {
  reserve: () => {},
  enroll: () => {},
  dismiss: () => {},
  collectRef: () => {
    throw new Error(
      'Context Provider is not mounted, collection of ref is not possible'
    )
  },
} satisfies LayoutContextValue

const LayoutPortalsContext = React.createContext<LayoutContextValue>(
  defaultLayoutPortalValue
)

export const createLayoutPortalToken = (token: string) => Symbol(token)

export const LayoutPortalsProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const tokenToObserver = useRef<Record<symbol, PortalElement>>({})

  const value = useMemo(() => {
    const ensureObservablesAreCreated = (token: symbol) => {
      if (tokenToObserver.current[token]) {
        return
      }

      const newObservable = new Observable<HTMLElement | null>(null)
      tokenToObserver.current[token] = newObservable
    }

    /**
     * Create the token data with a observable so it can be used by the portals
     *
     * @param token
     */
    const reserve = (token: symbol) => {
      ensureObservablesAreCreated(token)
    }

    /**
     * Enroll the ref element into the portal provider so the portal and
     * host are always in sync. This method is run when the ref of the host is set forcing
     * the portal to update (via the observable)
     *
     * @param token
     * @param element
     */
    const enroll = (token: symbol, element: HTMLElement) => {
      const value = tokenToObserver.current[token]

      // Check if the current observable value equals the new enrolled
      // one, if it is no need updating
      if (!value || value.state() === element) {
        return
      }

      tokenToObserver.current[token].setState(element)
    }

    /**
     * Dismiss the token portal observable
     *
     * @param token
     */
    const dismiss = (token: symbol) => {
      const observable = tokenToObserver.current[token]

      if (!observable) {
        ErrorHandler.shouldNeverHappen(
          `A dismissing Portal with token "${token.description}" isn't registered `
        )
        return
      }

      tokenToObserver.current[token].setState(null)

      if (observable.subscriberCount() >= 1) {
        // Removing the observable with portals still listening might
        // cause the observable to not re-mount properly. Given the portal
        // the portal connection will only be re-established when re-rendering
        return
      }

      delete tokenToObserver.current[token]
    }

    /**
     * Collects the observable from the context to provide the portal user the
     * ref that should be used
     *
     * @param token
     * @returns Observable
     */
    const collectRef = (token: symbol) => {
      ensureObservablesAreCreated(token)
      return tokenToObserver.current[token]
    }

    return { reserve, enroll, dismiss, collectRef }
  }, [])

  return (
    <LayoutPortalsContext.Provider value={value}>
      {children}
    </LayoutPortalsContext.Provider>
  )
}

/**
 * Retrieves a layout portal from the context, it's an alternative way to use an
 * existing layout portal. Apart from this hook we pass down the layout props
 * from the layout to the child component, but this isn't convenient if you
 * need to use the portal in a component down in the React render tree.
 */
export const useLayoutContext = () => {
  const contextValue = React.useContext(LayoutPortalsContext)
  return contextValue
}

/**
 * Creates a layout portal, it also stores the portal in a context.
 */
export const useCreateLayoutHost = (token: symbol) => {
  const { reserve, enroll, dismiss } = useLayoutContext()

  // Create the observable to sync refs between host and portal
  // Reserving the token in advance allows the observable to be
  // available for the portal before the ref is known.
  reserve(token)

  useEffect(() => {
    // Clean up when unmounting
    return () => {
      dismiss(token)
    }
  }, [dismiss, token])

  return (element: HTMLElement | null) => {
    if (!element) {
      return
    }

    enroll(token, element)

    if (element && !element.dataset['portalId']) {
      element.dataset['portalId'] = token.description
    }
  }
}

export interface LayoutPortalProps
  extends React.PropsWithChildren<{ token: symbol }> {}

export const LayoutPortal = ({ children, token }: LayoutPortalProps) => {
  const { collectRef } = useLayoutContext()
  const [, update] = useReducer(a => {
    return a + 1
  }, 0)

  const observable = collectRef(token)
  const ref = observable.state()

  const cachedRef = useRef(ref)
  cachedRef.current = ref

  useLayoutEffect(() => {
    // Subscribe to the host ref updates and update the portal if needed
    const disconnect = observable.subscribe(() => {
      update()
    })

    // We check if the observable state has changed from the
    // moment the component is mounted until the useLayoutEffect is run
    //
    // Because there might be a gap lost from the moment the observable is mounted and subscribed
    // til the render
    if (observable.state() !== cachedRef.current) {
      update()
    }

    return () => {
      disconnect()
    }
  }, [observable])

  if (!ref) {
    // we need to identify why the ref is not available
    return null
  }

  return createPortal(<>{children}</>, ref)
}

export const createLayoutPortal = (token: symbol) => {
  const TokenLayoutPortal = ({ children }: React.PropsWithChildren<{}>) => {
    return <LayoutPortal token={token}>{children}</LayoutPortal>
  }

  return TokenLayoutPortal
}
