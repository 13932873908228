import React, { useState } from 'react'
import { useKey } from 'react-use'
import { Route } from 'react-router'

import { routes, useFlag } from '@sketch/modules-common'
import { shortcuts, keyWithoutModifier } from '@sketch/utils'

import { useSortingSettings } from '../../hooks/useSortSettings'

import { SearchBar } from '../SearchBar'
import { SearchBarOld } from '../SearchBarOld'
import { SortDropdown } from '../SortDropdown'
import {
  Flex,
  NavigationSelector,
  Tooltip,
  useForTablet,
  useResponsiveDropdown,
  useSearchExpanded,
  useMobileScrolled,
} from '@sketch/components'
import { ReactComponent as BulletList } from '@sketch/icons/bullet-list-24'
import { ReactComponent as SquareGrid } from '@sketch/icons/square-grid-24'

// GraphQL
import {
  useGetDocumentListSettingsQuery,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'
// Here DocumentLayout are used in non exhaustive way
// therefore build should not break after SketchQL deployments
// eslint-disable-next-line no-restricted-imports
import { DocumentLayout } from '@sketch/gql-types/expansive'

import {
  NavbarWrapper,
  NavbarSection,
  SearchBarWrapper,
  Divider,
  SortIcon,
  SortButton,
} from './ShareNavbar.styles'

const DOCUMENT_LAYOUT = [
  {
    type: 'icon' as const,
    label: 'Grid Layout',
    value: 'GRID' as const,
    icon: SquareGrid,
    shortcut: shortcuts.gridView,
  },
  {
    type: 'icon' as const,
    label: 'List Layout',
    value: 'LIST' as const,
    icon: BulletList,
    shortcut: shortcuts.listView,
  },
]

const SortSettings: React.FC<{}> = () => {
  const [hoveringButton, setHoveringButton] = useState(false)

  const [sortDropdownContent, sortDropdownButtonProps] = useResponsiveDropdown({
    dropdown: SortDropdown,
    placement: 'bottom',
    hide: ['clickedOutsideContent'],
    usePortal: true,
  })
  const { sortLabel } = useSortingSettings()

  return (
    <Route
      path={[
        routes.WORKSPACE_SHARES.template(),
        routes.WORKSPACE_DRAFTS.template(),
        routes.WORKSPACE_LIBRARIES.template(),
        routes.WORKSPACE_SHARED_WITH_ME.template(),
        routes.WORKSPACE_TEMPLATES.template(),
        routes.WORKSPACE_PROJECT.template(),
        routes.WORKSPACE_COLLECTION.template(),
      ]}
    >
      <Flex
        mr={2}
        onMouseEnter={() => setHoveringButton(true)}
        onMouseLeave={() => setHoveringButton(false)}
      >
        <Tooltip
          content={`Sort by: ${sortLabel}`}
          placement="bottom"
          visible={hoveringButton && !sortDropdownButtonProps['aria-expanded']}
          disableWhenTouchDevice
        >
          <SortButton data-testid="sort-dropdown" {...sortDropdownButtonProps}>
            <SortIcon />
          </SortButton>
        </Tooltip>
      </Flex>
      {sortDropdownContent}
      <Divider />
    </Route>
  )
}

interface ShareNavbarProps {
  userRole: WorkspaceMinimalFragment['userRole']
  title?: React.ReactNode
  showViewOptions?: boolean
}

const ShareNavbar: React.FC<ShareNavbarProps> = props => {
  const { userRole, title, showViewOptions = true } = props
  const isTabletAndBigger = useForTablet()
  const isMobile = !isTabletAndBigger
  const mobileScrolled = useMobileScrolled()

  const { data, updateQuery } = useGetDocumentListSettingsQuery()
  const { documentsLayout } = data || {}
  const isNestedProjectsOn = useFlag('nested-projects')

  const { searchExpanded, setSearchExpanded } = useSearchExpanded()

  /**
   * Manager shortcuts:
   * - Search (/)
   * - View as Grid ([)
   * - View as List (])
   */
  useKey(keyWithoutModifier(shortcuts.search), function () {
    const inputElement = document!.querySelector(
      'input[name="search"]'
    ) as HTMLInputElement

    if (!searchExpanded) {
      setSearchExpanded(true)
    }

    // Needed to fire `focus` after the keydown, that avoids filling the search
    // with `/`
    setTimeout(function () {
      inputElement?.focus()
    }, 0)
  })

  useKey(
    keyWithoutModifier([shortcuts.gridView, shortcuts.listView]),
    function (event) {
      const radioElements = document!.querySelectorAll(
        'input[name="document layout"]'
      ) as NodeListOf<HTMLInputElement>

      const key = event.key as
        | typeof shortcuts.gridView
        | typeof shortcuts.listView
      const viewMode = DOCUMENT_LAYOUT.find(layout => layout.shortcut === key)

      if (!viewMode) {
        return
      }

      const radioElement = Array.from(radioElements).find(
        el => el.value === viewMode.value
      )

      if (radioElement) {
        updateQuery(previous => ({
          ...previous,
          documentsLayout: viewMode.value as DocumentLayout,
        }))
      }
    }
  )

  const mobile = (
    <NavbarWrapper>
      <NavbarSection align="start">
        {mobileScrolled ? title : null}
      </NavbarSection>
      {showViewOptions ? (
        <NavbarSection align="end">
          <SortSettings />
          <NavigationSelector
            name="document layout"
            items={DOCUMENT_LAYOUT}
            selected={documentsLayout}
            hideTooltip={true}
            onChange={({ value }) => {
              updateQuery(previous => ({
                ...previous,
                documentsLayout: value as DocumentLayout,
              }))
            }}
          />
        </NavbarSection>
      ) : null}
    </NavbarWrapper>
  )

  return isMobile ? (
    mobile
  ) : (
    <NavbarWrapper>
      <NavbarSection align="start">
        {showViewOptions ? (
          <>
            <NavigationSelector
              name="document layout"
              items={DOCUMENT_LAYOUT}
              selected={documentsLayout}
              hideTooltip={false}
              onChange={({ value }) => {
                updateQuery(previous => ({
                  ...previous,
                  documentsLayout: value as DocumentLayout,
                }))
              }}
            />

            <SortSettings />
          </>
        ) : null}

        <SearchBarWrapper>
          {isNestedProjectsOn ? (
            <SearchBar userRole={userRole} />
          ) : (
            <SearchBarOld userRole={userRole} />
          )}
        </SearchBarWrapper>
      </NavbarSection>
    </NavbarWrapper>
  )
}

export default ShareNavbar
