import { dataIdFromObject } from '@sketch/graphql-cache'

import { handleFetchMore } from '@sketch/components'

import { useOnEvent } from '@sketch/utils'

import { useGetCollectionSharesQuery } from '@sketch/gql-types'
import { ShareSearchFilter } from '../../shares/hooks/useSearchFiltersOld'
import { ShareSortOrder } from '../../shares/hooks/useSortSettings'
interface UseCollectionProps {
  projectId: string
  collectionId: string
  search?: string
  filters?: ShareSearchFilter[]
  sort: ShareSortOrder
}

const entriesPath = ['project', 'collection', 'shares', 'entries']

export const useCollectionsShares = ({
  projectId,
  collectionId,
  search,
  filters = [],
  sort,
}: UseCollectionProps) => {
  const { data, loading, error, fetchMore, refetch } =
    useGetCollectionSharesQuery({
      variables: {
        projectIdentifier: projectId,
        collectionIdentifier: collectionId,
        search: {
          name: search,
          filters,
        },
        after: null,
        sortOrder: sort,
      },
    })

  const after = data?.project.collection.shares.meta.after || null

  const loadMoreHandler = handleFetchMore(fetchMore, entriesPath, {
    dataIdFromObject,
    after,
  })

  useOnEvent('workspaceShareRefresh', ({ collectionIdentifier }) => {
    if (collectionIdentifier === collectionId) {
      refetch()
    }
  })

  return {
    project: data?.project,
    loading,
    error,
    fetchMore: loadMoreHandler,
  }
}
