import { dataIdFromObject } from '@sketch/graphql-cache'

import { isTruthy, useOnEvent } from '@sketch/utils'

import { handleFetchMore } from '@sketch/components'

import { useGetStorageItemsQuery } from '@sketch/gql-types'

import { StorageItemFilter } from '../../../storageItems/hooks'
// If StorageItemFilterType enum will be expanded by BE, TypeScript still should pass
// therefore we can ignore this warning
// eslint-disable-next-line no-restricted-imports
import { StorageItemFilterType } from '@sketch/gql-types/expansive'

interface UseGetStorageItemsProps {
  workspaceId: string
  projectId: string
  search?: string
  filters?: StorageItemFilter
}

const storageItemsEntriesPath = ['project', 'storageItems', 'entries']

const parseFilters = (
  filtersObj: StorageItemFilter | undefined,
  search: string | undefined
): {
  filters: StorageItemFilterType[] | undefined
  includeAllNestedLevels: boolean
} => {
  const includeAllNestedLevels = search
    ? !filtersObj?.searchIgnoringNestedFolders
    : false

  if (!filtersObj?.documents) {
    return { filters: undefined, includeAllNestedLevels }
  }

  const filtersRaw: (StorageItemFilterType | false)[] = [
    filtersObj.documents.userCanEdit && 'I_CAN_EDIT',
    filtersObj.documents.withPrototypes && 'HAS_PROTOTYPES',
  ]
  const filters = filtersRaw.filter(isTruthy)

  return { filters, includeAllNestedLevels }
}

export const useGetStorageItems = ({
  workspaceId,
  projectId,
  search,
  filters,
}: UseGetStorageItemsProps) => {
  const { data, fetchMore, refetch, ...rest } = useGetStorageItemsQuery({
    variables: {
      projectIdentifier: projectId,
      search,
      ...parseFilters(filters, search),
      after: null,
    },
    // This fetch policy is needed to make sure the nested projects are shown
    // correctly in the project view after restoring a project from trash
    fetchPolicy: 'cache-and-network',
    shouldInvalidatePrevious: (prev, curr) =>
      prev?.projectIdentifier !== curr?.projectIdentifier,
  })

  useOnEvent(
    'workspaceShareRefresh',
    ({ workspaceIdentifier, projectIdentifier }) => {
      // Not the workspace visible ignore then
      if (
        workspaceIdentifier !== workspaceId ||
        projectIdentifier !== projectId
      ) {
        return
      }

      refetch()
    }
  )

  useOnEvent('storageItemTransferred', ({ location }) => {
    const { destination } = location

    // Not the workspace visible ignore then
    if (
      !destination.projectId ||
      destination.projectId !== projectId ||
      destination.workspaceId !== workspaceId
    ) {
      return
    }

    refetch()
  })

  useOnEvent('nestedProjectCreated', ({ parentProjectId }) => {
    if (parentProjectId !== projectId) return

    refetch()
  })

  const afterProjectItems = data?.project?.storageItems.meta.after ?? null

  const fetchMoreFilled = handleFetchMore(fetchMore, storageItemsEntriesPath, {
    dataIdFromObject,
    after: afterProjectItems,
  })
  const storageItems = data?.project?.storageItems.entries

  const totalCounts = {
    storageItems: data?.project?.storageItems?.meta.totalCount ?? 0,
    shares: data?.project?.sharesCount ?? 0,
    projects: data?.project?.nestedProjectsCount ?? 0,
  }

  return {
    ...rest,
    storageItems,
    fetchMore: fetchMoreFilled,
    data,
    totalCounts,
  }
}
