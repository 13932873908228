import React from 'react'

import { OpenInMacButton, routes } from '@sketch/modules-common'

import { EmptyStateDueToSearch } from '../../../shares/components/EmptyState'
import DocumentListEmptyState from '../../../shares/components/DocumentListEmptyState'
import { LinkButton } from '@sketch/components'

import ProjectHeader from '../ProjectHeader'

import {
  ProjectInSidebarAndHeaderFragment,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'
import { isMac } from '@sketch/utils'
import { BreadcrumbsData } from '../../types'
import { userCanEditInWorkspace } from '../../../workspace/utils'

const EMPTY_STATE_COPY = {
  standard: {
    editor: {
      title: 'No documents in this project yet',
      description:
        'You can add a document to a project when you save it in the Mac App — or simply move an existing Workspace document here.',
    },
    viewer: {
      title: 'No documents in this project yet',
      description: 'Only Editors can create documents.',
    },
  },
  drafts: {
    editor: {
      title: 'No draft documents yet',
      description:
        'Want to work on a private document? Create a document in the Mac app, then save it to My Drafts.',
    },
    /**
     * Viewers can also have a drafts folder in some scenarios.
     * For example: when a user is a normal editor with a Drafts folder but gets downgraded to viewer,
     * it keeps the drafts folder even if it is empty.
     */
    viewer: {
      title: "You haven't added anything to My Drafts",
      description: 'Only Editors can create documents.',
    },
  },
}

function getEmptyStateContent(
  isDraftsProject: boolean,
  userIsEditor: boolean,
  name: string,
  deleted: boolean
) {
  const projectType = isDraftsProject ? 'drafts' : 'standard'

  if (deleted) {
    return {
      title: 'No documents in deleted project',
      description: `Restore “${name}” if you want to access or add documents to it.`,
    }
  }

  const userType = userIsEditor ? 'editor' : 'viewer'

  return EMPTY_STATE_COPY[projectType][userType]
}

interface ProjectEmptyStateProps {
  workspace: WorkspaceMinimalFragment
  project: ProjectInSidebarAndHeaderFragment
  breadcrumbs: BreadcrumbsData | undefined
  hasFilters: boolean
  onClearFilters: () => void
}

const ProjectEmptyState: React.FC<ProjectEmptyStateProps> = ({
  workspace,
  project,
  hasFilters,
  onClearFilters,
  breadcrumbs,
}) => {
  const isDraftProject = project.projectType === 'PERSONAL_DRAFTS'
  const isMacUser = isMac()

  const isEditor = userCanEditInWorkspace(workspace)

  const content = getEmptyStateContent(
    isDraftProject,
    isEditor,
    project.name,
    !!project?.deletedAt
  )

  const openMacAppAction =
    isMacUser && isEditor && !project.deletedAt ? <OpenInMacButton /> : null

  const allDocumentsAction = !isDraftProject && (
    <LinkButton
      buttonStyle="secondary-32"
      external={false}
      to={routes.WORKSPACE_SHARES.create({
        workspaceId: workspace.identifier,
      })}
    >
      View All Documents
    </LinkButton>
  )

  const emptyState = hasFilters ? (
    <EmptyStateDueToSearch
      typeName="documents"
      icon="search"
      onClear={onClearFilters}
    />
  ) : (
    <DocumentListEmptyState
      title={content.title}
      description={content.description}
      editorialContent={
        isDraftProject ? 'empty-state-my-drafts' : 'empty-state-project'
      }
      actions={
        <>
          {openMacAppAction}
          {allDocumentsAction}
        </>
      }
    />
  )

  return (
    <>
      <ProjectHeader
        project={project!}
        workspace={workspace}
        breadcrumbs={breadcrumbs}
        workspaceAccessLevel={project?.workspaceAccessLevel!}
      />

      {emptyState}
    </>
  )
}

export default ProjectEmptyState
