import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'

import { dataIdFromObject } from '@sketch/graphql-cache'

import {
  LoadingPlaceholder,
  Flex,
  AspectRatioGrid,
  AspectRatioGridResponseProp,
  handleFetchMore,
  InfiniteList,
  LoadingState,
} from '@sketch/components'
import EmptyState from '../../../components/EmptyStateOld'
import LoadingError from '../../components/Layout/LoadingError'
import { VersionLink } from '../../../../versioning'
import { Layout } from '../../components/Layout'
import {
  GroupName,
  Group,
  ItemImageWrapper,
  ListGridSeparator,
} from '../../components/Grid'
import { SymbolItem } from './SymbolItem'

import useScrollReset from '../../hooks/useScrollReset'
import { useFlag, useAnalytics } from '@sketch/modules-common'
import { useSearchComponentsContext } from '../../context/SearchComponentsContext'
import { useSelectedGroupContext } from '../../context/SelectedGroupContext'
import { useCustomGridContext } from '../../context/CustomGridContext'
import { useTrackTimeInComponentPage } from '../../hooks'
import { useGetComponentsCount, useGetSymbols } from '../../operations'

import { groupStyles, getGroupFromURL } from '../../utils'
import { INSPECTOR_URL_HASH } from '../../../constants'

import type { SymbolParsed } from '../../types'
import { Center } from '../../components/Center'
import ComponentsEmptyState from '../../../components/ComponentsEmptyState'

/**
 * STYLES
 */
const VersionLinkStyled = styled(VersionLink)(
  ({ theme }) => css`
    /* stylelint-disable-next-line selector-type-no-unknown */
    ${ItemImageWrapper}:hover {
      background-color: ${theme.colors.background.tertiary.C};
    }
  `
)

const SymbolsList = ({
  searchValue,
  path,
}: {
  searchValue: string
  path: string | null
}) => {
  useScrollReset()

  const isCustomGridEnabled = useFlag('cwv-custom-grid')
  const isFramesWebOn = useFlag('frames-web')

  const prevSearchRef = useRef<string | undefined>()

  const { defaultColumns, verticalRowSpaceGrid, minColumnWidth } =
    useCustomGridContext()

  const { trackEvent } = useAnalytics()

  const {
    shareIdentifier,
    versionIdentifier,
    entries,
    after,
    loading,
    fetchMore,
    error,
    hasComponentManifest,
    componentsState,
  } = useGetSymbols({
    search: searchValue,
    path: path === 'View All' ? null : path,
  })

  useEffect(() => {
    if (searchValue) {
      trackEvent('CWV - search', { type: 'symbols' })
    }
  }, [searchValue, trackEvent])

  useEffect(() => {
    // only update the searchValue after it has finished loading
    if (!loading) {
      prevSearchRef.current = searchValue
    }
  }, [searchValue, loading])

  if (error) {
    return <LoadingError error={error} componentType="Symbols" />
  }

  const switchingGroupToSearch =
    (path !== 'View All' || path === null) && searchValue

  const isLoadingNewSearch =
    loading &&
    searchValue &&
    (prevSearchRef.current === undefined ||
      prevSearchRef.current !== searchValue)

  if (
    (loading && !after) ||
    switchingGroupToSearch ||
    isLoadingNewSearch ||
    !versionIdentifier
  ) {
    return (
      <Flex justifyContent="center" flex="auto">
        <LoadingPlaceholder size="64px" />
      </Flex>
    )
  }

  if (!hasComponentManifest || componentsState === 'CANNOT_PROCESS') {
    return (
      <EmptyState
        title="Components aren’t available for this version"
        description=""
      />
    )
  }

  const symbolsGroups = groupStyles(entries)
  const groupsNames = Object.keys(symbolsGroups).sort()

  if (groupsNames.length === 0) {
    if (searchValue !== '') {
      return (
        <EmptyState
          title={`No search results for "${searchValue}"`}
          description="No search results."
          icon="search"
        />
      )
    }

    if (path !== '' && path !== 'View All') {
      return (
        <EmptyState
          title={`The group "${path}" does not exist in this version.`}
          description=""
          icon="symbol"
        />
      )
    }

    return (
      <EmptyState
        title="No symbols"
        description={
          <>
            This Document does not contain any{' '}
            <a href="https://www.sketch.com/docs/designing/symbols/">Symbols</a>
            .
          </>
        }
        icon="symbol"
      />
    )
  }

  const generateSymbolList = (symbol: SymbolParsed, groupName: string) => {
    return (
      <VersionLinkStyled
        key={`${symbol.identifier}-${versionIdentifier}`}
        onClick={() => {
          if (searchValue) {
            trackEvent('CWV - search click', { type: 'symbols' })
          }
        }}
        to={
          isFramesWebOn
            ? {
                routeKey: 'FRAME',
                routeParams: {
                  shareID: shareIdentifier,
                  frameUUID: symbol.frame.uuid,
                },
                state: { groupId: groupName, symbolName: symbol.name },
                hash: INSPECTOR_URL_HASH,
              }
            : {
                routeKey: 'ARTBOARD_DETAIL',
                routeParams: {
                  shareID: shareIdentifier,
                  permanentArtboardShortId:
                    symbol.artboard.permanentArtboardShortId!,
                },
                state: { groupId: groupName, symbolName: symbol.name },
                hash: INSPECTOR_URL_HASH,
              }
        }
      >
        <SymbolItem
          key={`${symbol.identifier}-${versionIdentifier}`}
          symbol={symbol}
          isSearch={searchValue !== ''}
          groupName={groupName}
          searchValue={searchValue}
        />
      </VersionLinkStyled>
    )
  }

  // The main difference between the search view and the normal view is that we
  // don't group items when searching, that's why we need to tweak the list generation
  if (searchValue !== '') {
    const columnsOnSearch = isCustomGridEnabled
      ? defaultColumns
      : // This `splice` modifies the last 2 values replacing them for 4 and 5
        ((defaultColumns as number[]).splice(
          -3,
          3,
          3,
          4,
          5
        ) as AspectRatioGridResponseProp)

    return (
      <InfiniteList
        canLoadMore={after !== null}
        onLoadMore={handleFetchMore(
          fetchMore,
          ['share', 'version', 'document', 'components', 'entries'],
          { dataIdFromObject, after: after || null }
        )}
      >
        <AspectRatioGrid
          verticalRowSpace={verticalRowSpaceGrid}
          columns={columnsOnSearch}
          gutterSize={32}
          minWidth={minColumnWidth}
        >
          {groupsNames.map(groupName =>
            symbolsGroups[groupName].map((symbol: SymbolParsed) =>
              generateSymbolList(symbol, groupName)
            )
          )}
        </AspectRatioGrid>
      </InfiniteList>
    )
  }

  return (
    <InfiniteList
      canLoadMore={after !== null}
      onLoadMore={handleFetchMore(
        fetchMore,
        ['share', 'version', 'document', 'components', 'entries'],
        { dataIdFromObject, after: after || null }
      )}
    >
      {groupsNames.map((groupName, key) => (
        <Group key={`${groupName}-${versionIdentifier}`}>
          {key !== 0 && <ListGridSeparator />}
          {groupName !== ' ' && <GroupName groups={groupName} />}
          <AspectRatioGrid
            minWidth={minColumnWidth}
            verticalRowSpace={verticalRowSpaceGrid}
            columns={defaultColumns}
            gutterSize={32}
          >
            {symbolsGroups[groupName].map((symbol: SymbolParsed) =>
              generateSymbolList(symbol, groupName)
            )}
          </AspectRatioGrid>
        </Group>
      ))}
    </InfiniteList>
  )
}

/**
 * MAIN COMPONENT
 */

interface SymbolsProps {
  userIsEditor: boolean
}

export const Symbols: React.FC<SymbolsProps> = ({ userIsEditor }) => {
  const { totalComponents, loading, componentsState } = useGetComponentsCount()
  const { search } = useSearchComponentsContext()
  const { selected, setSelected } = useSelectedGroupContext()
  const group = getGroupFromURL()

  useTrackTimeInComponentPage('symbols')

  if (
    componentsState === 'CAN_PROCESS' ||
    componentsState === 'PROCESSING' ||
    loading ||
    totalComponents === false
  ) {
    return (
      <Center>
        <div>
          <LoadingState />
          <p>Loading components…</p>
        </div>
      </Center>
    )
  }

  if (totalComponents === 0) {
    return <ComponentsEmptyState userIsEditor={userIsEditor} />
  }

  if (search) {
    setSelected('View All')
  }

  const path = selected ? selected : group

  return (
    <Layout>
      <SymbolsList searchValue={search} path={path} />
    </Layout>
  )
}
