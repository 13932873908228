import styled, { css, keyframes } from 'styled-components'

import { selectors } from '@sketch/constants'
import { breakpoint } from '@sketch/global-styles'
import { NavbarExtended } from '../NavbarExtended'
import { DocumentHeader } from '../DocumentHeader'
import { truncateStyles } from '../Truncate'
import { ActionsWrapper } from '../DocumentHeader/DocumentHeader.styles'

const NAVBAR_PORTAL_WIDTH = 524
const SIDEBAR_LEFT_WIDTH = 232
const WORKSPACE_AVATAR_TRANSLATION = 35

const slide = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-68px)
  }
  100% {
    opacity: 1;
    transform: translateY(0)
  }
`

export const StyledHeader = styled.header<{
  $sticky?: boolean
  $background?: boolean
  $mobileScrolled?: boolean
}>(
  ({ theme, $sticky, $background, $mobileScrolled }) => css`
    ::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding: 20px;
      z-index: 10;

      height: 68px;
      backdrop-filter: blur(15px);
      box-shadow: ${theme.colors.headerSticky.shadow};
      background-color: ${theme.colors.headerSticky.background};

      transition-property: opacity, transform;
      transition-duration: 150ms;
      transition-timing-function: linear;

      opacity: 0;
      transform: translateY(-68px);

      ${$background &&
      css`
        opacity: 1;
        transform: translateY(0);
      `};
    }

    /* For mobile we still want sticky header */
    ${breakpoint('base', 'sm')`
      width: 100%;
      position: sticky;
      top: 0;
      z-index: ${theme.zIndex[4]};
      box-shadow: ${theme.colors.headerSticky.shadow};
    `}

    [data-portal-id="header-slim"] {
      display: none;
    }

    ${DocumentHeader} {
      margin-bottom: 0;
    }

    /* Tweaks when scrolling on mobile */
    ${breakpoint('base', 'sm') &&
    css`
      /* WORKSPACE_AVATAR_TRANSLATION is the space we need to move the
      workspace avatar to push it out of the screen */
      ${selectors.mobileHeaderLeft} {
        transition: all 0.3s ease;

        ${$mobileScrolled &&
        css`
          transform: translateX(-${WORKSPACE_AVATAR_TRANSLATION}px);
          margin-right: -${WORKSPACE_AVATAR_TRANSLATION}px;
        `}
      }
      /* At the same time we move out the workspace avatar we want to reduce
      the opacity, until the point we completely hide it */
      ${selectors.workspaceDropdown} {
        transition: all 0.3s ease;
        opacity: ${$mobileScrolled ? 0 : 1};
      }
      /* For the navbar title we just want to fade in */
      ${selectors.navbarSectionTitle} {
        transition: opacity 0.3s ease;
        opacity: ${$mobileScrolled ? 1 : 0};
      }
    `}

    /* Overwrite existing styles for the slim header */
    ${$sticky &&
    breakpoint('sm') &&
    css`
      ${NavbarExtended} {
        ${breakpoint('base', 'sm')`
          background: ${theme.colors.background.secondary.A};
        `}

        [data-portal-id="header-slim"] {
          display: flex;
          height: 68px;
          animation: ${slide} ${theme.transitions.duration[2]}
            ${theme.transitions.timing.easeInOut};

          ${DocumentHeader} {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: calc(100% - ${SIDEBAR_LEFT_WIDTH}px);
            margin: 0;
            flex-shrink: 0;
            align-self: auto;
            max-width: 100%;

            /* NavPortal is fixed on the top right of the screen, we need to
            reserve that space */
            padding-right: ${NAVBAR_PORTAL_WIDTH}px;

            ${breakpoint('lg')`
              padding-right: ${NAVBAR_PORTAL_WIDTH}px;
            `}

            h1 {
              font-size: ${theme.fontSizes.F};
              margin-right: 16px;
              font-weight: 500;
              ${truncateStyles};
            }

            div {
              min-width: 0;
              margin: 0;
            }
          }

          ${ActionsWrapper} {
            flex-shrink: 0;
          }

          div {
            min-width: 0;
          }

          div[aria-label='Back to project'] {
            display: none;
          }

          button {
            font-size: ${theme.fontSizes.D};
            height: 30px;
            padding-left: 8px;
            padding-right: 8px;
            margin: 0 8px 0 0;
          }
        }

        [data-portal-id='navbar'] {
          flex-direction: row;
          align-items: center;
        }

        ${NavbarExtended} {
          background-color: transparent;
          backdrop-filter: none;
          padding: 0;
          height: auto;
          width: auto;
          position: static;
        }
      }
    `}
  `
)

export const SlimHeader = styled.div``

export const PageBeginnerMarker = styled.div``
