import React from 'react'

import { useBreakpoint } from '@sketch/components'

import WorkspaceSettingsNavbar from '../WorkspaceSettingsNavbar'

import { ContentWrapper } from './WorkspaceSettingsLayout.styles'

import { WorkspaceMinimalFragment } from '@sketch/gql-types'
import WorkspaceSettingsMobileNavbar from '../WorkspaceSettingsMobileNavbar'
import { CanRenderOptions } from '../../types'
import { WorkspaceSidebarLayoutExtraProps } from '../WorkspaceSidebarLayout'

interface WorkspaceSettingsLayoutProps {
  workspace: WorkspaceMinimalFragment
  canUseSso?: boolean
  hideNavbar: boolean
  canRender: CanRenderOptions
}

export function WorkspaceSettingsLayout(
  props: React.PropsWithChildren<WorkspaceSettingsLayoutProps> &
    WorkspaceSidebarLayoutExtraProps
) {
  const isMobile = !useBreakpoint('xs')

  const {
    workspace,
    canRender,
    children,
    hideNavbar,
    NavbarPortal,
    useOverrideLayoutProps,
  } = props

  const { name } = workspace
  const workspaceId = workspace.identifier

  useOverrideLayoutProps({
    title: name && `${name} Workspace Settings`,
  })

  const navBarContent = isMobile ? (
    <WorkspaceSettingsMobileNavbar
      canRender={canRender}
      workspaceId={workspaceId}
    />
  ) : (
    <WorkspaceSettingsNavbar workspaceId={workspaceId} canRender={canRender} />
  )

  return (
    <>
      <NavbarPortal>{hideNavbar ? null : navBarContent}</NavbarPortal>
      <ContentWrapper>{children}</ContentWrapper>
    </>
  )
}
