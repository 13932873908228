import { useHideCollectionsSetting } from '../../../collections/hooks'
import { useSortingSettings } from '../../../shares/hooks/useSortSettings'

// GraphQL
import { useGetProjectSharesQuery } from '@sketch/gql-types'

import { ShareSearchFilter } from '../../../shares/hooks/useSearchFiltersOld'

interface UseGetProjectSharesProps {
  projectIdentifier: string
  searchTerm?: string
  filters: ShareSearchFilter[]
}

export const useGetProjectShares = ({
  projectIdentifier,
  searchTerm,
  filters: baseFilters,
}: UseGetProjectSharesProps) => {
  const { hideCollections } = useHideCollectionsSetting()
  const { sort } = useSortingSettings()

  // the `NO_COLLECTION` filter will make sure that only shares outside of
  // collections are returned.
  const includeNoCollectionsFilter = !hideCollections

  const filters = [...baseFilters]
  if (includeNoCollectionsFilter) {
    filters.push('NO_COLLECTION')
  }

  return useGetProjectSharesQuery({
    variables: {
      shortId: projectIdentifier,
      after: null,
      search: {
        name: searchTerm || null,
        isCurrentVersionDownloadable: null,
        filters,
      },
      sortOrder: sort,
    },
    shouldInvalidatePrevious: (prev, curr) => prev?.shortId !== curr?.shortId,
  })
}
